import React, {useCallback, useEffect} from 'react'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import makeStyles from "@material-ui/styles/makeStyles";
import { version } from '../../common/enums';
import FeatureTable from './components/FeatureTable';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import api from '../../common/api';
import { useGlobalState } from '../../state';

const useStyles = makeStyles(theme => ({
  root: props => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
  }),
  bold: {
    fontWeight: 'bold'
  },
  headerSecondText: props => ({
    marginTop: theme.spacing(4),
    maxWidth: props.isDesktop ? '80%' : '100%',
  }),
  mainSection: {
    marginTop: theme.spacing(4),
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dividerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    width: '1px',
    height: '100%',
    backgroundColor: theme.palette.primary.main
  },
  videoContainer: props => ( {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: props.isDesktop ? theme.spacing(0) : theme.spacing(4),
  }),
  videoHeader: {
  },
  versionButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    // add a line bottom
    borderBottom: `1px solid ${theme.palette.primary.main}`
  },
  versionButton: {
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    borderRadius: '10px 10px 0 0',
    border: 'none',
    backgroundColor: theme.palette.common.white,
    minWidth: '100px',
    cursor: 'pointer',
    transition: "all 0.3s ease-in-out",

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      opacity: 0.8
    }
  },
  versionButtonActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      opacity: 1
    }
  },
  versionDescription: {
    marginTop: theme.spacing(2),
  },
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%', // 16:9 aspect ratio
    height: 0,
    overflow: 'hidden',
    width: '100%',
    maxWidth: '100%',
  },
  videoIframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  sectionFooter: {
    marginTop: theme.spacing(4),
  },
  versionSelect: props => ({
    width: props.isDesktop ? '400px' : '200px',
  }),
  saveButton: {
    marginLeft: theme.spacing(2),
  }
}))

const versionDetails = {
  lite: {
    description: 'The Lite version offers essential features for small-scale poultry management. Ideal for beginners and small farms.',
    videoSrc: 'https://player.vimeo.com/video/1063093599?h=c1a83168ce&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  },
  pro: {
    description: 'The Pro version includes advanced tools for medium to large farms. Enhanced analytics and reporting features.',
    videoSrc: 'https://player.vimeo.com/video/1063093636?h=d022592b5a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  },
  proPlus: {
    description: 'The Pro+ version provides comprehensive solutions for large-scale operations. Includes all Pro features plus premium support.',
    videoSrc: 'https://player.vimeo.com/video/1063093536?h=ed178c39fa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  }
};

function Welcome() {
  const versionKeys = Object.keys(version)
  const [selectedDemoVersion, setSelectedDemoVersion] = React.useState(versionKeys[0])
  const [selectedVersion, setSelectedDVersion] = React.useState(null)
  const [isUserVersionSaved, setIsUserVersionSaved] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });
  const classes = useStyles({isDesktop})

  const history = useHistory();
  const [{ user }, dispatch] = useGlobalState();

  const handleVersionSave = () => {
    const payload = {
      versionID: version[selectedVersion],
    }
    setLoading(true);
    const response = api.post('/activateTrial', payload)
    response.then((res) => {
      const userNewData = res.user;
      const onboardingTasks = res.onboardingTasks;
        dispatch({
          type: 'setProfile',
          user: {
            ...user,
            isTrialActivated: userNewData.isTrialActivated,
            subscriptionStatus: userNewData.subscriptionStatus,
            versionID: userNewData.versionID,
            versionName: selectedVersion,
            token: userNewData.token,
            onboardingTasks,
          }
        });
        setIsUserVersionSaved(true);
        history.push('/');
      })
    response.catch(() => {
        alert('Failed to save version. Please try again.')
      }).finally(() => {
        setLoading(false);
      });
  }

  const handleRouteChange = useCallback((location, action) => {
    // Check if user has selected a version before leaving the page
    if (!['/welcome', "/sign-in"].includes(location.pathname)) {
      alert('Please select a version before leaving.');
      history.push('/welcome');
    }
  }, [history]);

  useEffect(() => {
    if (!isUserVersionSaved) {
      const unlisten = history.listen(handleRouteChange);
      return () => {
        unlisten();
      };
    }
  }, [handleRouteChange, history, isUserVersionSaved]);

  const handleVersionChange = (event) => {
    setSelectedDVersion(event.target.value)
    dispatch({
      type: 'setTempVersion',
      user: {
        ...user,
        versionID: version[event.target.value] || null,
      }
    });
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3" >Choose the right <span className={classes.bold}>Version</span></Typography>
      <Typography variant="h5" className={classes.headerSecondText}>
        Choosing the right <span className={classes.bold}>Version based on you needs is critical at this stage</span>. We have 3 different version of the app. 
        A basic lite version, a moderate pro version and the complex Pro+ version
        </Typography>
        <Grid container justifyContent='center' className={classes.mainSection}>
          <Grid item xs={12} md={5} className={classes.tableContainer}>
            <FeatureTable />
          </Grid>
          <Grid item xs={12} md={1} className={classes.dividerContainer}>
            <div className={classes.divider}></div>
          </Grid>
          <Grid item xs={12} md={5} className={classes.videoContainer}>
            <Typography variant="h4" className={classes.videoHeader}>Watch <span className={classes.bold}>Demo</span></Typography>
            <div className={classes.versionButtonContainer}>
              {versionKeys.map((version) => (
                <button 
                  className={`${classes.versionButton} ${selectedDemoVersion === version ? classes.versionButtonActive : ''}`} 
                  onClick={() => setSelectedDemoVersion(version)}
                >
                  {version === "proPlus" ? "Pro+" : version.charAt(0).toUpperCase() + version.slice(1)}
                </button>
              ))}
            </div>
            <Typography className={classes.versionDescription} variant="h6">{versionDetails[selectedDemoVersion].description}</Typography>
            <div className={classes.videoWrapper}>
              <iframe 
                title={`video-${selectedDemoVersion}`}
                src={versionDetails[selectedDemoVersion].videoSrc}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className={classes.videoIframe}
              >
              </iframe>
            </div>
          </Grid>
        </Grid>
        <div className={classes.sectionFooter}>
          <Typography variant="h6">Choose your Version</Typography>
          <Grid container justifyContent='center'>
            <Grid item>
              <FormControl className={classes.versionSelect}>
              <InputLabel>Select Version</InputLabel>
              <Select onChange={handleVersionChange} value={selectedVersion}>
                {versionKeys.map((key) => (
                  <MenuItem value={key}>{key === "proPlus" ? "Pro+" : key.charAt(0).toUpperCase() + key.slice(1) }</MenuItem>
                ))}
              </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button 
                className={classes.saveButton} 
                variant="contained" 
                color="primary" 
                disabled={loading}
                onClick={handleVersionSave}
                >
                  Save
              </Button>
            </Grid>
          </Grid>
        </div>
    </div>
  )
}

export default Welcome
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import InputLabel from "@material-ui/core/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogTitle } from "../../../components";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import red from "@material-ui/core/colors/red";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import api from "../../../common/api";
import { MaterialTable } from "../../../components";
import validate from "validate.js";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import Switch from '@material-ui/core/Switch';

import { useGlobalState } from '../../../state';
import {version} from '../../../common/enums';
import useSearchStyles from "../../../common/searchStyle"
import { getCurrentTimeByAccount, updateOnboardingTaskStatus } from "../../../common/functions"
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.text.default,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  button: {
    margin: theme.spacing(2, 0),
  },
  typo: {
    marginLeft: theme.spacing(0.25),
    marginTop: theme.spacing(-1),
    fontSize: "initial",
  },
  typoRed: {
    marginLeft: theme.spacing(0.25),
    marginTop: theme.spacing(-1),
    fontSize: "initial",
    color: red[900],
  },
  redChip: {
    backgroundColor: "#d52121",
  },
  blueChip: {
    backgroundColor: "#39a3dd",
  },

  requiredField : {
    display:"flex"
  },
  requiedSwitch:{
    marginLeft:theme.spacing(2),
    marginTop:theme.spacing(2),
    fontSize: "initial",
    display:"flex"
  },
  checkSwitch : {
    marginTop:"-10px",
  },
  
}));

const NewBulkDailyEntry = ({
  handleClose,
  showDialog,
  editMode = false,
  sheds,
  materialLists,
  farmID,
  getMinClosingFeed,
  minClosingFeed,
  showError,
  deleteErrorMessage,
  dailyEntries
}) => {
  const classes = useStyles();

  const [{user}, dispatch] = useGlobalState();
  const [BulkEntryDate, setBulkEntryDate] = useState(null);
  const [bulkDailyEntry, setBulkDailyEntry] = useState(null);
  const [SelectedShed, setSelectedShed] = useState(null);
  const [Notification, setNotification] = useState(null);
  const [Disabled, setDisabled] = useState(true);
  const [MinClosingFeedMessage, setMinClosingFeedMessage] = useState(
    classes.blueChip
  );
  const [compulsoryColSwicth,setCompulsoryColSwitch] = useState(true)
  const closeButtonClass = useSearchStyles()
  var columns = [
    {
      title: "Shed",
      field: "shedName",
      headerStyle: { position: "sticky", left: "0", zIndex: "999", backgroundColor: "rgba(36, 42, 51, 1)"},
      cellStyle: { position: "sticky", left: "0", zIndex: "1", backgroundColor: "#FFFFFF"},
      
      required:true,
      render: function (props) {
        let index = sheds.findIndex((el) => el.id === props.shedID);
        props.shedIndex = index;
        return (
          <InputLabel id="type-select-outlined-label">
            {props.shedName}
          </InputLabel>
        );
      },
    },
    {
      title: "Mortality",
      field: "mortality",
      type: "numeric",
      required:true,
      render: function (props) {
        return (
          <TextField
            name="mortality"
            type="number"
            value={props.mortality === -1 ? "" : props.mortality}
            variant="outlined"
            style={{
              backgroundColor: "#ffffff",
            }}
            onChange={(event) => {
              props.mortality = parseFloat(event.target.value);
              props.createTime = BulkEntryDate;
              props.entryDate = BulkEntryDate;
              setBulkDailyEntry([...bulkDailyEntry]);
              setDisabled((prevDisabled) =>
                prevDisabled === true ? !prevDisabled : prevDisabled
              );
            }}
          />
        );
      },
    },
    {
      title: "Egg Type",
      field: "materialTypeName",
      required:true,
      render: function (props) {
        let index = materialLists.findIndex(
          (el) => el.id === props.materialTypeID
        );
        props.materialIndex = index;
        return (
          <Select
            id="egg-type-select"
            value={props.materialIndex}
            onChange={(event) => {
              props.materialTypeID = parseFloat(
                materialLists[event.target.value].id
              );
              props.materialIndex = event.target.value = parseFloat(
                event.target.value
              );
              props.materialTypeName =
                materialLists[event.target.value].name;
              props.createTime = BulkEntryDate;
              props.entryDate = BulkEntryDate;
              setBulkDailyEntry([...bulkDailyEntry]);
              setDisabled((prevDisabled) =>
                prevDisabled === true ? !prevDisabled : prevDisabled
              );
            }}
          >
            {materialLists !== undefined && materialLists !== null
              ? materialLists.map((materialList, index) => (
                  <MenuItem key={materialList.id} value={index}>
                    {materialList.name}
                  </MenuItem>
                ))
              : ""}
          </Select>
        );
      },
    },
    {
      title: "Egg Sales (In Trays)",
      field: "eggSales",
      type: "numeric",
      required:true,
      render: function (props) {
        return (
          <TextField
            name="eggSales"
            type="number"
            value={props.eggSales === -1 ? "" : props.eggSales}
            variant="outlined"
            style={{
              backgroundColor: "#ffffff",
            }}
            onChange={(event) => {
              props.eggSales = parseFloat(event.target.value);
              props.createTime = BulkEntryDate;
              props.entryDate = BulkEntryDate;
              setBulkDailyEntry([...bulkDailyEntry]);
              setDisabled((prevDisabled) =>
                prevDisabled === true ? !prevDisabled : prevDisabled
              );
            }}
          />
        );
      },
      // render: function (rowData) {},
    },
    {
      title: "Egg Closing Stock (In Trays)",
      field: "eggClosingStock",
      type: "numeric",
      required:true,
      render: function (props) {
        return (
          <TextField
            name="eggClosingStock"
            type="number"
            variant="outlined"
            style={{
              backgroundColor: "#ffffff",
            }}
            value={
              props.eggClosingStock === -1
                ? ""
                : props.eggClosingStock
            }
            onChange={(event) => {
              props.eggClosingStock = parseFloat(event.target.value);
              props.createTime = BulkEntryDate;
              props.entryDate = BulkEntryDate;
              setBulkDailyEntry([...bulkDailyEntry]);
              setDisabled((prevDisabled) =>
                prevDisabled === true ? !prevDisabled : prevDisabled
              );
            }}
          />
        );
      },
    },
    {
      title: "Egg breakage",
      field: "eggBreakage",
      type: "numeric",
      required:true,
      render: function (props) {
        return (
          <TextField
            name="eggBreakage"
            type="number"
            variant="outlined"
            style={{
              backgroundColor: "#ffffff",
            }}
            value={props.eggBreakage === -1 ? "" : props.eggBreakage}
            onChange={(event) => {
              props.eggBreakage = parseFloat(event.target.value);
              props.createTime = BulkEntryDate;
              props.entryDate = BulkEntryDate;
              setBulkDailyEntry([...bulkDailyEntry]);
              setDisabled((prevDisabled) =>
                prevDisabled === true ? !prevDisabled : prevDisabled
              );
            }}
          />
        );
      },
    },
    {
      title: "Damaged Eggs",
      field: "damagedEggs",
      type: "numeric",
      required:true,
      render: function (props) {
        return (
          <TextField
            name="damagedEggs"
            type="number"
            variant="outlined"
            style={{
              backgroundColor: "#ffffff",
            }}
            value={props.damagedEggs === -1 ? "" : props.damagedEggs}
            onChange={(event) => {
              props.damagedEggs = parseFloat(event.target.value);
              props.createTime = BulkEntryDate;
              props.entryDate = BulkEntryDate;
              setBulkDailyEntry([...bulkDailyEntry]);
              setDisabled((prevDisabled) =>
                prevDisabled === true ? !prevDisabled : prevDisabled
              );
            }}
          />
        );
      },
    },
    {
      title: "Closing Feed",
      field: "closingFeed",
      type: "numeric",
      required:true,
      render: function (props) {
        return (
          <TextField
            name="closingFeed"
            type="number"
            variant="outlined"
            style={{
              backgroundColor: "#ffffff",
            }}
            value={props.closingFeed === -1 ? "" : props.closingFeed}
            onChange={(event) => {
              props.closingFeed = parseFloat(event.target.value);
              props.createTime = BulkEntryDate;
              props.entryDate = BulkEntryDate;
              setSelectedShed(props.shedName);
              if(user.versionID !== version.lite){
                getMinClosingFeed(
                  props.shedID,
                  BulkEntryDate,
                  sheds[props.shedIndex].name
                );

                if (
                  minClosingFeed !== undefined &&
                  minClosingFeed !== null &&
                  minClosingFeed.minClosingFeed !== undefined &&
                  minClosingFeed.minClosingFeed !== 0
                ) {
                  if (
                    event.target.value > minClosingFeed.minClosingFeed
                  ) {
                    setMinClosingFeedMessage(classes.redChip);
                  } else {
                    setMinClosingFeedMessage(classes.blueChip);
                  }
                }
              }

              setBulkDailyEntry([...bulkDailyEntry]);
              setDisabled((prevDisabled) =>
                prevDisabled === true ? !prevDisabled : prevDisabled
              );
            }}
          />
        );
      },
    },
    {
      title: "Body weight",
      field: "bodyWeight",
      type: "numeric",
      required:false,
      render: function (props) {
        return (
          <TextField
            name="bodyWeight"
            type="number"
            variant="outlined"
            style={{
              backgroundColor: "#ffffff",
            }}
            value={
              props.bodyWeight === -1
                ? 0
                : props.bodyWeight
            }
            onChange={(event) => {
              props.bodyWeight = parseFloat(event.target.value);
              props.createTime = BulkEntryDate;
              props.entryDate = BulkEntryDate;
              setBulkDailyEntry([...bulkDailyEntry]);
              setDisabled((prevDisabled) =>
                prevDisabled === true ? !prevDisabled : prevDisabled
              );
            }}
          />
        );
      },
    },
    {
      title: "Uniformity",
      field: "uniformity",
      type: "numeric",
      required:false,
      render: function (props) {
        return (
          <TextField
            name="uniformity"
            type="number"
            variant="outlined"
            style={{
              backgroundColor: "#ffffff",
            }}
            value={
              props.uniformity === -1
                ? 0
                : props.uniformity
            }
            onChange={(event) => {
              props.uniformity = parseInt(event.target.value);
              props.createTime = BulkEntryDate;
              props.entryDate = BulkEntryDate;
              setBulkDailyEntry([...bulkDailyEntry]);
              setDisabled((prevDisabled) =>
                prevDisabled === true ? !prevDisabled : prevDisabled
              );
            }}
          />
        );
      },
    },
    {
      title: "No Of Male Labours",
      field: "noOfMaleLabours",
      type: "numeric",
      required:false,
      render: function (props) {
        return (
          <TextField
            name="noOfMaleLabours"
            type="number"
            variant="outlined"
            style={{
              backgroundColor: "#ffffff",
            }}
            value={
              props.noOfMaleLabours === -1
                ? 0
                : props.noOfMaleLabours
            }
            onChange={(event) => {
              props.noOfMaleLabours = parseInt(event.target.value);
              setBulkDailyEntry([...bulkDailyEntry]);
              setDisabled((prevDisabled) =>
                prevDisabled === true ? !prevDisabled : prevDisabled
              );
            }}
          />
        );
      },
    },
    {
      title: "No Of Female Labours",
      field: "noOfFemaleLabours",
      type: "numeric",
      required:false,
      render: function (props) {
        return (
          <TextField
            name="noOfFemaleLabours"
            type="number"
            variant="outlined"
            style={{
              backgroundColor: "#ffffff",
            }}
            value={
              props.noOfFemaleLabours === -1
                ? 0
                : props.noOfFemaleLabours
            }
            onChange={(event) => {
              props.noOfFemaleLabours = parseInt(event.target.value);
              setBulkDailyEntry([...bulkDailyEntry]);
              setDisabled((prevDisabled) =>
                prevDisabled === true ? !prevDisabled : prevDisabled
              );
            }}
          />
        );
      },
    },
    {
      title: "Overtime Hours",
      field: "overtimeHours",
      type: "numeric",
      required:false,
      render: function (props) {
        return (
          <TextField
            name="uniformity"
            type="number"
            variant="outlined"
            style={{
              backgroundColor: "#ffffff",
            }}
            value={
              props.overtimeHours === -1
                ? 0
                : props.overtimeHours
            }
            onChange={(event) => {
              props.overtimeHours = parseInt(event.target.value);
              setBulkDailyEntry([...bulkDailyEntry]);
              setDisabled((prevDisabled) =>
                prevDisabled === true ? !prevDisabled : prevDisabled
              );
            }}
          />
        );
      },
    },
  ];

  if(user.versionID === version.lite){
    columns.push({
      title: "Feed Received (in Kgs)",
      field: "feedReceived",
      type: "numeric",
      required:true,
      render: function (props) {
        return (
          <TextField
            name="feedReceived"
            type="number"
            variant="outlined"
            style={{
              backgroundColor: "#ffffff",
            }}
            value={
              props.feedReceived === -1
                ? 0
                : props.feedReceived
            }
            onChange={(event) => {
              props.feedReceived = parseFloat(event.target.value);
              props.createTime = BulkEntryDate;
              props.entryDate = BulkEntryDate;
              setBulkDailyEntry([...bulkDailyEntry]);
              setDisabled((prevDisabled) =>
                prevDisabled === true ? !prevDisabled : prevDisabled
              );
            }}
          />
        );
      },
    });
  }


  let requiredColumn = columns.filter(col => col.required);


  function resetBeforeEdit() {
    if (showDialog) {
      //fetch Today Data by Timezone
      getCurrentTimeByAccount().then(dateString => {
        if(dateString){
            const dateParts = dateString.split('T')[0];
            let entryDate = moment.utc(dateParts); // Parse the date as UTC
            handleFilterStartDateChange(entryDate)
        }
      }).catch(err => {
          console.log("error",err)
      })
      setBulkDailyEntry(null);
      setBulkEntryDate(null);
      setNotification(null);
      setDisabled(true);
      setSelectedShed(null);
      setMinClosingFeedMessage(classes.blueChip);
    }
  }

  function onSubmit() {
    if (
      bulkDailyEntry !== undefined &&
      bulkDailyEntry !== null &&
      Array.isArray(bulkDailyEntry) &&
      bulkDailyEntry.length > 0
    ) {
      setMinClosingFeedMessage(classes.blueChip);
      const ValidEntries = [];
      const remainingEntries = [];
      for (const Entries of bulkDailyEntry) {
        let shedType = sheds[Entries.shedIndex].shedTypeName;
        let schema;
        if (
          Entries !== undefined &&
          Entries !== null &&
          Entries.shedIndex !== undefined &&
          Entries.shedIndex !== null &&
          sheds[Entries.shedIndex] !== undefined &&
          sheds[Entries.shedIndex] !== null
        ) {
          if (shedType === "Growyer" || shedType === "Chick") {
            schema = {
              mortality: {
                presence: { allowEmpty: false, message: "is required" },
                type: "number",
                numericality: {
                  greaterThanOrEqualTo: 0,
                  message: "must be a positive number or zero"
                }
              },
                eggSales: {
                  presence: { allowEmpty: false, message: 'is required' },
                  numericality: {
                      greaterThanOrEqualTo: 0,
                      message:"must be a positive number or zero"
                  }
              },
              eggClosingStock: {
                  presence: { allowEmpty: false, message: 'is required' },
                  numericality: {
                      greaterThanOrEqualTo: 0,
                      message:"must be a positive number or zero"
                  }
              },
              eggBreakage: {
                  presence: { allowEmpty: false, message: 'is required' },
                  numericality: {
                      greaterThanOrEqualTo: 0,
                      message:"must be a positive number or zero"
                  }
              },
              damagedEggs: {
                  presence: { allowEmpty: false, message: 'is required' },
                  numericality: {
                      greaterThanOrEqualTo: 0,
                      message:"must be a positive number or zero"
                  }
              },
              closingFeed: {
                presence: { allowEmpty: false, message: "is required" },
                type: "number",
                numericality: {
                  greaterThanOrEqualTo: 0,
                  message: "is required",
                },
              },
              materialIndex: {
                presence: {
                  allowEmpty: false,
                },
                numericality: {
                  greaterThan: -1,
                },
              },
            };
          } else if (shedType === "Layer") {
            schema = {
              mortality: {
                presence: { allowEmpty: false, message: "is required" },
                type: "number",
                numericality: {
                  greaterThanOrEqualTo: 0,
                  message: "is required",
                },
              },
              eggSales: {
                presence: { allowEmpty: false, message: 'is required' },
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            eggBreakage: {
                presence: { allowEmpty: false, message: 'is required' },
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            damagedEggs: {
                presence: { allowEmpty: false, message: 'is required' },
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
              closingFeed: {
                presence: { allowEmpty: false, message: "is required" },
                type: "number",
                numericality: {
                  greaterThanOrEqualTo: 0,
                  message: "is required",
                },
              },
              eggClosingStock: {
                presence: { allowEmpty: false, message: "is required" },
                type: "number",
                numericality: {
                  greaterThanOrEqualTo: 0,
                  message: "is required",
                },
              },
              materialIndex: {
                presence: {
                  allowEmpty: false,
                },
                numericality: {
                  greaterThan: -1,
                },
              },
            };
          }
        }
        let result = validate(Entries, schema);
        let obj = {
          ...Entries,
          bodyWeight : (Entries.bodyWeight) ? parseFloat(Entries.bodyWeight.toFixed(2)) : 0 ,
          feedReceived : (user.versionID === version.lite && Entries.feedReceived) ? parseFloat(Entries.feedReceived.toFixed(2)) : 0 ,
        };
        if (result === undefined) {

          //optional Field
          if (!compulsoryColSwicth && Entries) {
            let optionalField = Entries
            if(optionalField.bodyWeight < 0) {
              setNotification(
                `Error: Body Weight for ${
                  Entries.shedName 
                } cannot be Negative`
              );
              return;
             }
             if(optionalField.uniformity < 0 ) {
              setNotification(
                `Error: uniformity for ${
                  Entries.shedName 
                } cannot be Negative`
              );
              return;
             }

          }

            
          // lite version validation
          if (user.versionID === version.lite) {
            let feedReceived = Entries.feedReceived
            if(feedReceived < 0) {
             setNotification(
               `Error: Feed Received for ${
                 Entries.shedName 
               } is required`
             );
             return;
            }
         }
          //remove -ve values before hitting server
          let cleanEntries = {
            ...obj,
            damagedEggs: obj.damagedEggs === -1 ? 0 : obj.damagedEggs,
            eggBreakage: obj.eggBreakage === -1 ? 0 : obj.eggBreakage,
            eggClosingStock:
              obj.eggClosingStock === -1 ? 0 : obj.eggClosingStock,
            eggSales: obj.eggSales === -1 ? 0 : obj.eggSales,
            closingFeed: obj.closingFeed === -1 ? 0 : obj.closingFeed,
            mortality: obj.mortality === -1 ? 0 : obj.mortality,
          };
          ValidEntries.push(cleanEntries);
        } else {
          /**
           *
           * Note: The below two condition check happens if the non required fields of certain sheds are
           * entered and required fields are empty and the save button is clicked, concerned notification
           * will be thrown to UI stating to fill in the required fields of those sheds.
           */
          if (
            (shedType === "Growyer" || shedType === "Chick") &&
            (obj.eggSales > 0 ||
              obj.eggClosingStock > 0 ||
              obj.damagedEggs > 0 ||
              obj.eggBreakage > 0 ||
              obj.materialIndex > -1) &&
            obj.mortality === 0 &&
            obj.closingFeed === 0
          ) {
            setNotification(
              `Mortality and Closing feed are required for shed ${Entries.shedName}`
            );
            return;
          } else if (
            shedType === "Layer" &&
            (obj.damagedEggs > 0 || obj.eggBreakage > 0 || obj.eggSales > 0) &&
            obj.eggClosingStock === 0 &&
            obj.materialIndex === -1 &&
            obj.mortality === 0 &&
            obj.closingFeed === 0
          ) {
            setNotification(
              `Mortality,Closing feed,Egg Closing Stock,Egg Type are required for shed ${Entries.shedName}`
            );
            return;
          }
          remainingEntries.push(obj);
        }
        if (
          result !== undefined &&
          result !== null &&
          Object.keys(result).length < Object.keys(schema).length
        ) {
          if (Object.values(result)[0].join(" ").includes("Material index")) {
            setNotification(
              `Error: Egg Type is required for ${Entries.shedName}`
            );
            return;
          }
          setNotification(
            `Error: ${Object.values(result)[0].join(" ")} for ${
              Entries.shedName
            }`
          );
          return;
        }
      }
      if (ValidEntries.length > 0) {
        api
          .post(`farm/${farmID}/bulk-daily-entry`, [...ValidEntries])
          .then((res) => {
            setNotification("Bulk entries added successfully!");

            // get all onboardingTasks and update if it is the first daily entry
            updateOnboardingTaskStatus(user, dispatch, "CREATE_DAILY_ENTRY", farmID);

            setBulkDailyEntry([...remainingEntries, ...res]);
          })
          .catch((err) => {
            if (err.message) {
              setNotification(`Error: ${err.message}`);
            }
          })
          .finally(() => {});
      } else {
        setNotification("Kindly add daily entries.");
      }
    } else {
      setNotification("Kindly add daily entries.");
    }
  }

  useEffect(resetBeforeEdit, [showDialog]);

  function deleteNotification() {
    setNotification(null);
  }

  function handleCompFieldChange() {
    setCompulsoryColSwitch(!compulsoryColSwicth)
  }

  function handleFilterStartDateChange(date) {
    setBulkEntryDate(date);
    setNotification(null);
    let payload = {
      startDate: date,
      endDate: date,
      farmId: parseInt(farmID, 10),
    };
    const response = api.post(`bulk-daily-entries`, {
      ...payload,
    });
    response
      .then((res) => {
        setBulkDailyEntry(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }

  useEffect(() => {
    if (
      minClosingFeed !== undefined &&
      minClosingFeed !== null &&
      minClosingFeed.minClosingFeed !== undefined &&
      minClosingFeed.minClosingFeed !== 0
    ) {
      setNotification(
        <Typography
          className={classes.typo}
          style={{ fontSize: "small", marginTop: "0px", color: "white" }}
        >
          Closing Feed Should be less than or equal to{" "}
          {minClosingFeed.minClosingFeed} for shed{" "}
          {SelectedShed !== null ? SelectedShed : ""} for date{" "}
          {BulkEntryDate !== null
            ? moment(BulkEntryDate).format("DD-MM-YYYY")
            : ""}
        </Typography>
      );
    }
    //eslint-disable-next-line
  }, [minClosingFeed, MinClosingFeedMessage, SelectedShed, BulkEntryDate]);

  return (
    <Dialog
      disableBackdropClick
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullScreen
    >
      <DialogTitle>
        Bulk Daily entry
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider
              class={classes.datePicker}
              utils={DateFnsUtils}
            >
              <div class={classes.requiredField}>
                
                <DatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Entry Date"
                  format="dd/MM/yyyy"
                  animateYearScrolling
                  value={BulkEntryDate || null}
                  name="entryDate"
                  style={{ backgroundColor: "#ffffff" }}
                  onChange={handleFilterStartDateChange}
                />
                <div class={classes.requiedSwitch}>

                  <p>Show required fields only</p>

                  <div  class={classes.checkSwitch}>
                        <Switch
                      
                          checked={compulsoryColSwicth}
                          onChange={()=>handleCompFieldChange()}
                          color="primary"
                          marginLeft="-20px"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>
                </div>
                
              </div>
            </MuiPickersUtilsProvider>            
          </FormControl>
          {Notification ? (
            <Chip
              className={MinClosingFeedMessage}
              color="primary"
              label={Notification}
              onDelete={deleteNotification}
              size="large"
              style={{
                margin: "18px",
                marginLeft: "260px",
                height: "36px",
              }}
            />
          ) : (
            ""
          )}

          <MaterialTable
            columns={ compulsoryColSwicth ? requiredColumn : columns}
            style={{ opacity: "1" }}
            data={bulkDailyEntry || []}
            options={{
              actionsColumnIndex: -1,
              search: false,
              toolbar: true,
              maxBodyHeight: "600px",
              paging: false,
              // render: (row) => console.log(columnIndex) 
            }}
            title="Daily Entries"
          />
        </div>
      </DialogContent>
      <DialogActions>
      <Button
          className={closeButtonClass.close}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          className={classes.signInButton}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disabled={Disabled}
          onClick={onSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewBulkDailyEntry;

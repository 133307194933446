import React from 'react'
import { List } from '@material-ui/core'
import TaskListItem from './TaskListItem';

function TaskList({tasks, farms, className, setTasksListIsOpen}) {
  const [selectedFarm, setSelectedFarm] = React.useState(undefined);
  return (
    <List className={className}>
        {tasks !== undefined && tasks !== null && tasks !== '' && 
          tasks.map((task, index) =>{
            // determine if the current task is the next task to be completed.
            // a task is considered the next task if all previous tasks are completed.
            // if tasks are not completed in sequential order, the first non-completed task will be the next task.
            // for farm-related tasks, it also checks if the task is completed for the selected farm. 
            const isNextTask = tasks.slice(0, index).every(t => {
              if (t.route.includes('[farm_id]')) {
                return t.isCompleted && t.completedFarms && t.completedFarms.includes(selectedFarm);
              }
              return t.isCompleted;
            }) && (!task.isCompleted || (task.route.includes('[farm_id]') && (!task.completedFarms || !task.completedFarms.includes(selectedFarm))));
            return (
              <TaskListItem 
                key={index} 
                task={task} 
                farms={farms} 
                index={index} 
                isNextTask={isNextTask} 
                selectedFarm={selectedFarm} 
                setSelectedFarm={setSelectedFarm} 
                setTasksListIsOpen={setTasksListIsOpen}
              />
            )
          })
        }
      </List>
  )
}

export default TaskList
import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { useGlobalState } from '../../state';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ProgressCircle from './ProgressCircle'


const useStyles = makeStyles(theme => ({
  root: props => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding:'5px 10px',
    borderRadius:  '100px',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    position: 'fixed',
    bottom: props.isSmallScreen ? theme.spacing(1) : theme.spacing(4),
    right: props.isSmallScreen ? theme.spacing(1) : theme.spacing(4),
    boxShadow:  theme.shadows[3],
    zIndex: 1000,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    minWidth: 250, // making the button's width and height fixed even the content inside it changes
    minHeight: 44,
  }),
  span: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
  icon: {
    width:'25px',
    marginRight: '5px'
  },
  progressCircle: {
    marginRight: '5px'
  },
  text: {
    color: theme.palette.primary.contrastText,
  }
}));

function GetStartedButton({ className, handleClick, isOpen, totalTaskCount, completedTasksCount }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true
  });

  const classes = useStyles({ isSmallScreen });
  const [{ user }] = useGlobalState();

  const taskProgressData = `${completedTasksCount}/${totalTaskCount}`;
  const taskProgressPercentage = (completedTasksCount / totalTaskCount) * 100;

  return (
    user && user.Config.onboarding_tasks ? (
      <div className={`${classes.root} ${className}`} onClick={handleClick}>
        {isOpen ? (
          <>
            <CancelIcon />
            <Typography variant="h6" className={classes.text}>Close</Typography>
          </>
        ): (
          <>
          <span className={classes.span}>
            <img className={classes.icon} src={"/images/icons/task-list.svg"} alt="taskListIcon" />
            <Typography variant="h6" className={classes.text}>Onboarding Task</Typography>
          </span>
          <span className={classes.span}>
            <ProgressCircle className={classes.progressCircle} percentage={taskProgressPercentage} />
            <Typography variant="h6" className={classes.text}>{taskProgressData}</Typography>
          </span>
          </>
        )}
        </div>
    ) : null
  );
}

export default GetStartedButton;
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';


import {Footer, Sidebar, Topbar} from './components';
import Chip from "@material-ui/core/Chip";
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { GetStarted } from '../../components/GetStarted';

const useStyles = makeStyles(theme => ({
    root: {

        height: '100%',
        [theme.breakpoints.down('sm')]: {
            //paddingTop: 56
        }

    },
    shiftContent: {
        paddingLeft: 240
    },
    content: {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        paddingTop:'5px',
        backgroundImage:"url('/images/poultry-back.png')",
        position: 'relative',
    },
    divMask: {
        background:theme.palette.backgroundOpacityColor,
        minHeight: '100vh'
    },
    body: {
        flex: 1,
        padding: '.5em',
        minHeight: '90vh'
    },
    messageContainer:{

            margin: theme.spacing(4)

    }
}));

const Main = props => {
    const {children} = props;
    const [errorText,setErrorText] = useState(undefined);
    const [messageText,setMessageText] = useState(undefined);

    const classes = useStyles({});
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    const shouldOpenSidebar = isDesktop ? true : openSidebar;

    const onErrorChipClose=()=>{
        setErrorText('');
    }

    const onMessageChipClose=()=>{
        setMessageText('');
    }

    return (

        <div
            className={clsx({
                [classes.root]: true,
                [classes.shiftContent]: isDesktop
            })}
        >
            {isDesktop?
                    ''
                :<Topbar onSidebarOpen={handleSidebarOpen}/> 
            }   
            <Sidebar
                onClose={handleSidebarClose}
                open={shouldOpenSidebar}
                variant={isDesktop ? 'persistent' : 'temporary'}
            />
            <main className={classes.content}>
                <GetStarted/>
                <div className={classes.divMask}>
                <Container maxWidth="xl" className={classes.body}>
                    <div className={classes.messageContainer}>
                        {
                            errorText ?
                                <Chip size="small" color="secondary" icon={<ErrorIcon/>} label={errorText} onDelete={onErrorChipClose}/> :
                                undefined
                        }
                        {
                            messageText ?
                                <Chip color="primary" icon={<CheckCircleIcon/>} onDelete={onMessageChipClose}
                                      label={messageText}/> :
                                undefined
                        }
                    </div>
                    {React.cloneElement(children, { showError: setErrorText,showMessage:setMessageText })}
                </Container>
                <Footer/>
                </div>
            </main>
        </div>
    );
};

Main.propTypes = {
    children: PropTypes.node
};

export default Main;

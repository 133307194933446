import React from "react";

const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ color, pct, radius, strokeWidth }) => {
  const circ = 2 * Math.PI * radius;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={radius}
      cx={radius + strokeWidth}
      cy={radius + strokeWidth}
      fill="transparent"
      stroke={strokePct !== circ ? color : ""} // remove color as 0% sets full circumference
      strokeWidth={strokeWidth}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    ></circle>
  );
};

const ProgressCircle = ({
  percentage,
  color = "white",
  radius = 80,
  strokeWidth = 30,
  size = 30,
  className = '',
}) => {
  const pct = cleanPercentage(percentage);
  const adjustedSize = radius * 2 + strokeWidth * 2;
  return (
    <svg width={size} height={size} className={className} viewBox={`0 0 ${adjustedSize} ${adjustedSize}`}>
      <g transform={`rotate(-90 ${adjustedSize / 2} ${adjustedSize / 2})`}>
        <Circle color="#d3d3d380" radius={radius} strokeWidth={strokeWidth} />
        <Circle color={color} pct={pct} radius={radius} strokeWidth={strokeWidth} />
      </g>
    </svg>
  );
};

export default ProgressCircle;
import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Typography, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    '& h4': {
      color: theme.palette.primary.contrastText,
    }
  },
  dialogContent: {
    overflow: 'hidden'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.primary.contrastText,
  },
}))

function VideoPopup({isOpen, onClose, videoUrl, title}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });
  const classes = useStyles({isSmallScreen});

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        style: {
          margin : isSmallScreen ? '0' : 'auto',
          width: isSmallScreen ? '100%' : '80%'
        }
      }}
      >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h4">{title}</Typography>
        <IconButton className={classes.closeButton} aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
      <iframe
          title={`video-${title}`}
          width="100%"
          height={isSmallScreen ? "220" : "315"}
          src={videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </DialogContent>
    </Dialog>
  )
}

export default VideoPopup
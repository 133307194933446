import api from '../common/api'
const reducer = (state, action) => {
    switch (action.type) {
        case 'setProfile':
            if (action.user) {
                window.localStorage.setItem('profile', JSON.stringify(action.user));
                window.localStorage.setItem('token', action.user.token);
                return {
                    ...state,
                    ...action.user,
                    isLoggedIn: (action.user.token && action.user.token.length > 0)
                };
            } else {
                return state;
            }
        case 'logout':
            api.post('sign-out',JSON.parse(localStorage.getItem("profile")))
            .then((res)=>{
                console.log(res);
                handleSignOut();       
            }).catch((err)=>{
                console.log(err);
                handleSignOut();
            })
            return {
                ...state,
                isLoggedIn: false
            };
        case 'config':
            let newState = state
            newState.Config[action.payload.name]=!action.payload.value;
            window.localStorage.setItem('profile', JSON.stringify(newState));
            return {
                ...newState
        };
        case 'setTempVersion':
            if (action.user) {
                return {
                    ...state,
                    ...action.user
                };
            }
            else {
                return state;
            }
        default:
            return state;
    }
};

export default reducer;

function handleSignOut() {
    window.localStorage.setItem('profile', '');
    window.localStorage.setItem('token', '');
    window.localStorage.removeItem('taxData')   
}
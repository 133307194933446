import React, {useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import {MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Switch from '@material-ui/core/Switch';
import {useGlobalState} from '../../state';
import Alert from '@material-ui/lab/Alert';
import {version} from "../../common/enums"
import {isAllOnboardingTasksCompleted} from "../../common/functions"
import UpdateIcon from '@material-ui/icons/Update';
import Chip from '@material-ui/core/Chip';
const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));
const ConfigView = ({showError}) => {
    const [{user}, dispatch] = useGlobalState();
    // console.log("user from config",user)
    const [loading, setLoading] = useState(false);
    const [configData, setConfigData] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [showAllTasksCompletedPopup, setShowAllTasksCompletedPopup] = useState(false)
    const [updateMessage, setUpdateMessage] = useState(false)
    function tableData(){
        showError('')
        let arr=[];
        for (const [key, value] of Object.entries(user.Config)) {
            if(user.versionID === version.proPlus){
                arr.push({name:key,value:value,label:key.toUpperCase().replace(/_/g, ' ')});
            } else  if((user.versionID === version.pro) && ((key === "truckeggs_as_goodEggs" || key === "onboarding_tasks") )) {
                arr.push({name:key,value:value,label:key.toUpperCase().replace(/_/g, ' ')});
            } else if((user.versionID === version.lite) && ((key === "onboarding_tasks") )){
                arr.push({name:key,value:value,label:key.toUpperCase().replace(/_/g, ' ')});
            }
           
        }
        setConfigData(arr);
    }
    if(configData.length===0){
        tableData();
    }
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    const fetchAccountTasksAndFarms = React.useCallback(async () => {
        try {
            const tasksResponse = await api.post('/getOnboardingTasks', { versionID: user.versionID });
            let farmsResponse = await api.get('farm').then(res => res).catch(err => {
                // if there is no farm, return []
                if (err.message === "No data found" && err.ErrorCode === 404) {
                    return [];
                } else {
                    throw err;
                }
            });
    
            if (tasksResponse.tasks !== user.onboardingTasks || farmsResponse !== user.farms) {
                dispatch({
                    type: 'setProfile',
                    user: {
                        ...user,
                        onboardingTasks: tasksResponse.tasks,
                        farms: farmsResponse,
                    }
                });
            }
        } catch (err) {
            console.log("Error fetching tasks and farms: ", err);
        }
    }, [dispatch, user]);

    const onUpdatingConfig = (config) => {
        setLoading(true);
        showError('');
        const req = {
            name : config.name,
            value : !config.value
        };

        // if user trying to turn on onboarding tasks and all tasks are already completed then show popup
        if (req.name === "onboarding_tasks" && req.value && user.onboardingTasks && isAllOnboardingTasksCompleted(user.onboardingTasks)) {
            setShowAllTasksCompletedPopup(true)
            setLoading(false);
            return;
        }

        const response = api.post(`updateConfiguration`, req);
        response.then((responseValue) => {
            // if user activating onboarding tasks and all tasks are not completed then fetch and update onboarding tasks and farms in local storage
            if (req.name === "onboarding_tasks" && req.value && responseValue.message !== "All Onboarding Tasks Completed") {
                fetchAccountTasksAndFarms();
            }

            // show tasks already completed popup if all the tasks are completed
            if (responseValue.message === "All Onboarding Tasks Completed"){
                setShowAllTasksCompletedPopup(true)
            }

            // if Configuration updated then update the state
            if (responseValue.message === "Configuration Updated Successfully"){
                dispatch({type:"config",payload:config});
                tableData();
                setUpdateMessage(true);
            }
            // console.log("state config after updating",user.Config)
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Subscriber not Updated');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(()=>{
            setLoading(false);
        })
    };

    const handleIsToggleChange = (rowData) =>{
        onUpdatingConfig(rowData);
    };
    const removeErrorMessage = () =>{
        setErrorMessage('');
    }
    const deleteUpdateMessage = () =>{
        setUpdateMessage(false);
    }

    return <div className={classes.root}>
        {errorMessage ?
    <Alert severity="error" onClick={removeErrorMessage}>{errorMessage}</Alert>
    : ''}
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Configuration
                </Typography>
            </Grid>
            {updateMessage ? <Chip  color="secondary" onDelete={deleteUpdateMessage} icon={<UpdateIcon/>} label="Updated. Please logout and login again for your changes to become effective" /> : ""}
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                // {title: 'id', field: 'id', hidden: true},
                {title: 'Name', field: "label", hidden: false, editable: 'never'},
                {title: 'Show/Hide', field: 'value', hidden: false, editable: 'never', 
                render : rowData=>{
                    return <Switch
                        checked={rowData.value}
                        onChange={()=>handleIsToggleChange(rowData)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                }},
            ]}
            data={configData}
            title='Configuration Table'
            options={{
                actionsColumnIndex: -1,
                search: false,
                showTitle: false,

                toolbar: true
            }}
        />
        <Dialog open={showAllTasksCompletedPopup}>
            <DialogTitle>you have already completed all onboarding tasks</DialogTitle>
            <DialogActions>
                <Button onClick={()=>setShowAllTasksCompletedPopup(false)} color="secondary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}

export default ConfigView;
import React from 'react'
import GetStartedButton from './GetStartedButton'
import TaskList from './TaskList'
import { makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import defaultTheme from '../../theme/palettes/defaultTheme'
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useGlobalState } from '../../state';
import ProgressCircle from './ProgressCircle'

const useStyles = makeStyles((theme) => ({
  root: props => ({
    width: '100%',
    maxWidth: props.isSmallScreen ? 300 : 360,
    backgroundColor: theme.palette.background.paper,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    height: '500px',
    maxHeight: '70vh',
    position: 'fixed',
    bottom: theme.spacing(9),
    right: props.isSmallScreen ? theme.spacing(1) : theme.spacing(4),
    zIndex: 100,
  }),
  header: {
    fontWeight: 'bold',
    height: '50px',
    padding: theme.spacing(2),
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    backgroundColor: defaultTheme.primary.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      color: theme.palette.primary.contrastText,
    },
    '& h6': {
    color: theme.palette.primary.contrastText,
    }
  },
  span: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
  body: {
    overflowY: 'scroll',
    height: '450px',
    maxHeight : `calc(70vh - 50px)`, // 50px is the height of the header
  },
  icon: {
    marginRight: '5px'
  },
}));

function GetStarted() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [completedTasksCount, setCompletedTasksCount] = React.useState(0)
  const location = useLocation();
  const [{ user }] = useGlobalState();
  
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true
  });
  const classes = useStyles({ isSmallScreen });

  React.useEffect(() => {
    if (user && user.onboardingTasks) {
      const completedTasks = user.onboardingTasks.filter(task => task.isCompleted).length;
      setCompletedTasksCount(completedTasks);
    }
  }, [user]);

  const taskProgressPercentage = (completedTasksCount / (user?.onboardingTasks?.length)) * 100;

  // Hide the component if the current path is '/welcome'
  if (location.pathname === '/welcome') {
    return null;
  }

  const handleClick = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
    {user?.Config?.onboarding_tasks && user?.onboardingTasks ? (
      <>
      {isOpen ? 
        <div id='get-started' className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h4" align="left">Get Started</Typography>
          <span className={classes.span}>
            <ProgressCircle className={classes.icon} percentage={taskProgressPercentage} />
            <Typography variant="h6" className={classes.headerProgressText}>{completedTasksCount}/{user.onboardingTasks.length}</Typography>
          </span>
        </div>
        <div className={classes.body}>
          <TaskList tasks={user.onboardingTasks} farms={user.farms} setTasksListIsOpen={setIsOpen}/>
        </div>
      </div>
        : null}
      <GetStartedButton
        isOpen={isOpen}
        handleClick={handleClick}
        totalTaskCount={user.onboardingTasks.length}
        completedTasksCount={completedTasksCount}
      />
    </>
    ) : null}
    </>
  )
}

export default GetStarted
import React, { useEffect } from 'react'
import { FormControl, InputLabel, ListItem, ListItemText, makeStyles, MenuItem, Select, useMediaQuery, useTheme, Divider, Typography } from '@material-ui/core'
import { Check, ArrowForwardIos } from '@material-ui/icons'
import { useHistory } from 'react-router-dom';
import VideoPopup from './VideoPopup';

const useStyles = makeStyles(theme => {
  return ({
    listItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column'
    },
    listItemHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    listItemText: {
      fontWeight: 'bold',
    },
    completedIcon: {
      color: theme.palette.success.main,
    },
    navigateButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      width: 35,
      height: 35,
      borderRadius: '100%',
      backgroundColor: theme.palette.primary.main,
      cursor: 'pointer',
      '& img': {
          width: '50%',
      },
      '&:hover': {
          backgroundColor: theme.palette.primary.dark,
      },
      '&:disabled': {
          backgroundColor: theme.palette.action.disabledBackground,
          color: theme.palette.action.disabled,
      }
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      marginBottom: theme.spacing(2),
    },
    watchVideoLink: {
      marginRight: theme.spacing(2),
      fontWeight: 'bold',
      textDecoration: 'underline',
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.dark
      }
    },
    taskDescription: {
      marginBottom: theme.spacing(1),
      width: '100%',
    },
    listItemBody: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      '& button': {
        alignSelf: 'flex-end'
      }
    },
    disabledNavigateButton: {
      color: theme.palette.action.disabled,
    },
  });
});

function TaskListItem({task, farms, index, isNextTask, selectedFarm, setSelectedFarm, setTasksListIsOpen}) {
  const [isFarmRoute, setIsFarmRoute] = React.useState(false);
  const [hasMultipleFarms, setHasMultipleFarms] = React.useState(false);
  const [isVideoPopupOpen, setIsVideoPopupOpen] = React.useState(false);

  const history = useHistory();
  
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });
  const classes = useStyles({isDesktop});

  useEffect(() => {
    if (task.route.includes('[farm_id]')) {
      setIsFarmRoute(true);
    }
  
    if (farms && farms.length > 1) {
      setHasMultipleFarms(true);
    } else if (farms && farms.length === 1) {
      setHasMultipleFarms(false);
      setSelectedFarm(farms[0].id);
    }
  }, [task, farms, selectedFarm, hasMultipleFarms, isFarmRoute, setSelectedFarm]);

  const handleChange = (event) => {
    setSelectedFarm(event.target.value);
  };

  const handleNavigate = (route) => {
    let routeWithFarm = route.replace('[farm_id]', selectedFarm);
    history.push(routeWithFarm);
    setTasksListIsOpen(false);
  };

  return (
    <>
      <ListItem key={index} className={classes.listItem}>
        <div className={classes.listItemHeader}>
          <ListItemText primary={`${index + 1}. ${task.text}`} classes={{ primary: classes.listItemText }} />
          {isNextTask ? (
            <button onClick={() => {setIsVideoPopupOpen(true)}} className={classes.watchVideoLink}>Watch Video</button>
          ) : (
            task.isCompleted && (!isFarmRoute || (isFarmRoute && task.completedFarms && task.completedFarms.includes(selectedFarm))) ? (
              <Check className={classes.completedIcon} />
            ) : (
              <ArrowForwardIos className={classes.disabledNavigateButton}/>
            )
          )}
        </div>
        {task.isCompleted && task.id === 1 && hasMultipleFarms ? ( // task.id:1 = ADD_FARM, show farm select box if multiple farms exist
          <FormControl className={classes.formControl}>
            <InputLabel id="get-started-select-label">Select Farm</InputLabel>
            <Select
              id="get-started-select"
              value={selectedFarm !== undefined ? selectedFarm : ''}
              onChange={handleChange}
              name="farmIndex"
            >
              {farms !== undefined && farms !== null && farms !== '' &&
                farms.map((farm, farmIndex) => (
                  <MenuItem key={farmIndex} value={farm.id}>{farm.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        ) : null}

        {isNextTask ?(
          <div className={classes.listItemBody}>
            <Typography variant="body1" className={classes.taskDescription}>
              {task.description}
            </Typography>
            <button 
              className={classes.navigateButton} 
              disabled={isFarmRoute && hasMultipleFarms && !selectedFarm} 
              onClick={() => handleNavigate(task.route)}
            >
              <img className={classes.icon} src={"/images/icons/navigate-task.svg"} alt="task navigate icon" />
            </button>
          </div>
          ) : null
        }
        {task.videoLink && isVideoPopupOpen &&(
          <VideoPopup
            isOpen={isVideoPopupOpen}
            onClose={() => setIsVideoPopupOpen(false)}
            videoUrl={task.videoLink}
            title={task.text}
           />
        )}
      </ListItem>
      <Divider />
    </>
  );
}

export default TaskListItem
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useGlobalState} from '../../state';
import  {Subscriptions as SubscriptionsView} from '../../views'
import { useState } from 'react';
import {version} from "../../common/enums"
import {   isUserHasPathAccess } from '../../pages/pathAccess';

const RouteWithLayout = ({layout: Layout, component: Component, skipLogin = false, path, ...rest}) => {

    const [{user}] = useGlobalState();
    const [errorText, setErrorText] = useState(undefined);

  
    var returnComponent;
    switch(true){
        case (user && user.isLoggedIn && !user.subscriptionStatus && !user.isTrialActivated && path !== "/welcome"):
            returnComponent = (
                <Redirect
                    to={{
                        pathname: '/welcome',
                        state: {
                            referrer: path
                        }
                    }}
                />
            );
            break;
        case ((user && user.isLoggedIn && user.isTrialActivated) && !user.subscriptionStatus):
            returnComponent = (
                <Route
                {...rest}
                render={matchProps => (
                    <Layout>
                     
                            <SubscriptionsView showError={setErrorText } errText = {errorText}  {...matchProps} />
                        
                    </Layout>
                )}
            />
            );
            break;

        case ((user && user.isLoggedIn) || skipLogin):
            switch(true){
                case (user && !isUserHasPathAccess(path,user)):
                    let pathName = (user.versionID === version.proPlus) ? "/dashboard":"/farm";
                    if(user.versionID === version.proPlus && user.role === "DRIVER"){
                        pathName = "/line-sale-driver";
                    }
                    returnComponent = (<Redirect
                        to={{
                            pathname: pathName,
                            state: {
                                referrer: path
                            }
                        }}
                    />);
                    break;
                
                default:
                    returnComponent = (<Route
                        {...rest}
                        render={matchProps => {
                            let isLine = false
                            if(matchProps.location.pathname.includes("line")) {
                                isLine = true
                            }
                           return (
                            <Layout>
                                    <Component {...matchProps} isLine={isLine} />
                                
                            </Layout>
                        )}}
                    />);
                    break;
            }
            break;

        default:
            returnComponent = (<Redirect
                to={{
                    pathname: '/sign-in',
                    state: {
                        referrer: path
                    }
                }}
            />);
            break;
    }
    return returnComponent;

};

RouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
    skipLogin: PropTypes.bool
};

export default RouteWithLayout;
import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import makeStyles from '@material-ui/styles/makeStyles';

// Build Hash for Cache Busting
const currentBuildHash = process.env.REACT_APP_BUILD_HASH;

const useStyles = makeStyles((theme) => ({
    myAlert: {
        color: "#ffffff",
        backgroundColor: "rgba(36, 42, 51, 0.9)",
        "& .MuiAlert-icon": {
            color: "#ffffff" // Changes only the info icon color to white
        }
    },
    updateButton: {
        color: "#ffffff",
    },
    snackbar: {
        top: 100, // Default top padding for desktop
        [theme.breakpoints.down("md")]: {
            top: 80, // Extra space for mobile header bar
        },
        [theme.breakpoints.up("md")]: {
            top: 20, // Extra space for mobile header bar
        }
    }
}));

function NewReleaseAlert() {
    const classes = useStyles();
    const [newVersion, setNewVersion] = useState(false);

    useEffect(() => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.addEventListener("controllerchange", () => {
                setNewVersion(true);
            });
        }
    }, []);

    useEffect(() => {
        const previousBuildHash = localStorage.getItem('appBuildHash');

        if (previousBuildHash && previousBuildHash !== currentBuildHash) {
            console.log("New version detected, showing update notification...");
            localStorage.setItem('appBuildHash', currentBuildHash);
            setNewVersion(true);
        } else {
            localStorage.setItem('appBuildHash', currentBuildHash);
        }
    }, []);

    const handleUpdate = () => {
        window.location.reload();
    };

    return (
        <Snackbar
            open={newVersion}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={6000}
            className={classes.snackbar}
        >
            <Alert
                className={classes.myAlert}
                severity="info"
                action={
                    <Button
                        className={classes.updateButton}
                        size="small"
                        onClick={handleUpdate}
                    >
                        Update
                    </Button>
                }
            >
                A new version is available! Click update to refresh.
            </Alert>
        </Snackbar>
    );
}

export default NewReleaseAlert;
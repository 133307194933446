import React from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import {BrowserRouter} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {appDefaultTheme} from './theme';
import Routes from './Routes';
import validate from 'validate.js';
import validators from './common/validators';
import NewReleaseAlert from "./components/Dialog/NewReleaseDialog"
//Global App State
import {appReducer, getInitialState, StateProvider} from './state';

//Adding Custom Validators - Refer top Common/Validators
validate.validators = {
    ...validate.validators,
    ...validators
};

function App() {
    return (
        <StateProvider reducer={appReducer} initialState={getInitialState()}>
            <ThemeProvider theme={appDefaultTheme}>
                <CssBaseline/>
                <BrowserRouter basename={process.env.REACT_APP_BASE_FOLDER}>
                    <Routes/>
                </BrowserRouter>
                <NewReleaseAlert />
            </ThemeProvider>
        </StateProvider>
    );
}

export default App;
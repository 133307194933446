import React, {useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog,MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import CustomerVendorDetail from "./components/customerVendorDetail";
import Icon from '@material-ui/core/Icon';
import {useGlobalState} from '../../state';
import { CSVReader } from 'react-papaparse';
import { useLocation } from 'react-router-dom'
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';
import {getAllTaxes} from '../../common/functions';
import CustomerVendorDetailsCSV from './templateFile/customer_vendor_details.csv';
import { updateOnboardingTaskStatus } from '../../common/functions';
// import { CustomDialog } from "../../components";

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    spacing: {
        marginLeft: theme.spacing(2),
    },
    download: {
        marginLeft: theme.spacing(1)
    }
}));

let currentCustomerVendorDetail = undefined;
let editMode = false;

const buttonRef = React.createRef()

const CustomerVendorDetailsView = ({showError, showMessage, history}) => {

    const [customerVendorDetails, setCustomerVendorDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showCustomerVendorDetailDialog, setShowCustomerVendorDetailDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [taxProfiles, settaxProfiles] = useState(null);
    const [taxProfilesMap, settaxProfilesMap] = useState(null);
    // const [open, setOpen] = useState(false)
    // const [bulkDatas, setBulkDatas] = useState([])
    // const [columns, setColumns] = useState([])
    const [isLine, setisLine] = useState(true)
    
    const urlPath = useLocation()

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});
    const [{user}, dispatch] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }

    function onNewCustomerVendorDetail(event) {
        editMode = false;
        event.preventDefault();
        setShowCustomerVendorDetailDialog(true);
    }

    useEffect(() => {
        getAllTaxes(api, settaxProfiles,["TCS","TDS"],function(taxProfileData){
            let map = new Map()
            for (const el of taxProfileData) {
                map.set(el.id,el)
            }
            settaxProfilesMap(map)
        })
    }, []);


    function handleCustomerVendorDetailClose() {
        setErrorMessage(null);
        currentCustomerVendorDetail = undefined;
        setShowCustomerVendorDetailDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }


    const onFarmDeleteConfirm = () => {
        if (currentCustomerVendorDetail && currentCustomerVendorDetail.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            // showError('');
            const response = api.delete(`customer-vendor-details/${currentCustomerVendorDetail.id}`);

            response.then(res => {
                if (res) {
                    let index = customerVendorDetails.findIndex((srcCustomerVendorDetail) => {
                        return srcCustomerVendorDetail.id === currentCustomerVendorDetail.id;
                    });
                    let newCustomerVendorDetails = [...customerVendorDetails];
                    newCustomerVendorDetails.splice(index, 1);
                    setCustomerVendorDetails(newCustomerVendorDetails);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        setErrorMessage('CustomerVendorDetail not Deleted');
                    else
                    setErrorMessage(err.message)
                } else {
                    setErrorMessage('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentCustomerVendorDetail = undefined;
            });

        }
    };

    const onDelete = (customerVendorDetail) => {
        currentCustomerVendorDetail = customerVendorDetail;
        setShowDeleteConfirm(true);
    };

    const onCustomerVendorDetailSave = (customerVendorDetail, setpopuploading) => {
        //setLoading(true);
        // showError('');
        showMessage('');
        let obj = {...customerVendorDetail}
        obj.isLineEnabled = isLine
        const response = api.post('customer-vendor-details', {
            ...obj,
            phoneNumber : obj.phoneNumber
        });

        response.then(res => {
            // update onboarding task if the customer vendor details is added for the first time
            updateOnboardingTaskStatus(user, dispatch, "ADD_CUSTOMERS_VENDORS");

            setCustomerVendorDetails([res, ...customerVendorDetails]);
            setShowCustomerVendorDetailDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('CustomerVendorDetail not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };


    const onCustomerVendorDetailEdit = (customerVendorDetail) => {
        currentCustomerVendorDetail = {...customerVendorDetail}
        editMode = true;
        setShowCustomerVendorDetailDialog(true);
    };

    const onCustomerVendorDetailUpdate = (customerVendorDetail, setpopuploading) => {   
        //setLoading(true);
        // showError('');
        const response = api.post(`customer-vendor-details/${customerVendorDetail.id}`, {
            ...customerVendorDetail,
            phoneNumber : customerVendorDetail.phoneNumber,
            openingBalance: parseInt( customerVendorDetail.openingBalance)
           
        });
        response.then((res) => {

            let index = customerVendorDetails.findIndex((srcCustomerVendorDetail) => {
                return srcCustomerVendorDetail.id === customerVendorDetail.id;
            });
            
            setCustomerVendorDetails([...customerVendorDetails.slice(0, index),
                {...customerVendorDetail
                   },
                ...customerVendorDetails.slice(index + 1)]);
            setShowCustomerVendorDetailDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('CustomerVendorDetail not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };

    //Get all CustomerVendorDetails API Call
    const getAllCustomerVendorDetails = () => {
        setShowCustomerVendorDetailDialog(false);
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('customer-vendor-details');

        response.then(res => {
           
            setCustomerVendorDetails([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No CustomerVendorDetails Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(getAllCustomerVendorDetails, []);

    
    useEffect(() => {
        if (urlPath !== undefined && urlPath !== null && urlPath.pathname.includes("details")) {
            setisLine(false)
        }
    }, [urlPath])

    let customerVendorDetailNameColumn = {
        render: rowData => <Mobile data={rowData}
                                   onEdit={() => {
                                       onCustomerVendorDetailEdit(rowData)
                                   }}
                                   onDelete={() => {
                                       onDelete(rowData)
                                   }}
        />
    };
    let actions = [];
    if (isDesktop) {
        customerVendorDetailNameColumn = {title: 'Primary Contact', field: 'primaryContact'};
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Customer Vendor Details',
                onClick: (event, rowData) => onCustomerVendorDetailEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Customer Vendor Details',
                onClick: (event, rowData) => onDelete(rowData)
            }
        ]
    }

    const handleOnFileLoad = (datas) => {
        setLoading(true)
        let cvDatas = []
        datas.forEach((singleData, index) => {

            if (index !== 0) {
                let cvData = {}
                cvData.isLineEnabled = singleData.data[0] === "0" ? false : true
                cvData.type = singleData.data[1] === "Vendor" ? 2 : 1
                cvData.primaryContact = singleData.data[2]
                cvData.companyName = singleData.data[3]
                cvData.phoneNumber = singleData.data[4]
                cvData.address = singleData.data[5]
                cvData.gstNumber = singleData.data[6]
                cvData.openingBalance = parseFloat(singleData.data[7])
                cvData.emailAddress = singleData.data[8]
                
                cvDatas.push(cvData)
            }
        })


        // let myDatas = []
        // let checkColumns = ["isLineEnabled", "openingBalance", "phoneNumber", "gstNumber"]

        // // Loop through datas
        // datas.forEach((row, index) => {

        //     let value = row.data;
            
        //     if (typeof(value.isLineEnabled) === "number"
        //         && typeof(value.phoneNumber) === "number"
        //         && typeof(value.gstNumber) === "number"
        //         && typeof(value.openingBalance) === "number") {

        //         let a = []
        //         for (const [k, v] of Object.entries(row.data)) {
        //             if (checkColumns.includes(k)) {
        //                 a.push({
        //                     value: v,
        //                     error: !(typeof(v) === "number")
        //                 })
        //             } else {
        //                 a.push({
        //                     value: v,
        //                     error: false
        //                 })
        //             }
        //         }
        //         myDatas.push({
        //             error: false,
        //             values: a
        //         })

        //         value["isLineEnabled"] = value.isLineEnabled === 1 ? true : false
        //         value["phoneNumber"] = value.phoneNumber.toString()
        //         value["gstNumber"] = value.gstNumber.toString()
        //         value["primaryContact"] = value.primaryContact.toString()
        //         value["openingBalance"] = parseFloat(value.openingBalance)
        //         value["type"] = value.type === "Customer" ? 1 : 2
                
        //         correctDatas.push(value)
        //     } else {
        //         let a = []
        //         for (const [k, v] of Object.entries(row.data)) {
        //             if (checkColumns.includes(k)) {
        //                 a.push({
        //                     value: v,
        //                     error: !(typeof(v) === "number")
        //                 })
        //             } else {
        //                 a.push({
        //                     value: v,
        //                     error: false
        //                 })
        //             }
        //         }
        //         myDatas.push({
        //             error: true,
        //             values: a
        //         })
        //     }            
        // })

        const response = api.post("bulk-customer-vendor-datails", cvDatas)

        response.then(res => {
            // update onboarding task if the customer vendor details is added for the first time
            updateOnboardingTaskStatus(user, dispatch, "ADD_CUSTOMERS_VENDORS");
            
            setCustomerVendorDetails([...(res.reverse()), ...customerVendorDetails])
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Create Bulk CustomerVendorDetail not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });

        // setBulkDatas(myDatas)
        // let cols = ["", "Row"]
        // cols = [...cols,...datas[0].meta.fields]
        // setColumns(cols)
        // setOpen(true)

    }
    // const handleOnRemoveFile = (data) => {
    //     console.log(data)
    // }

    const handleOnError = (err) => {
        setErrorMessage(err)
    }

    // const configOptions = {
    //     header: true,
    //     dynamicTyping: true,
    //     transformHeader: header =>
    //       header
    //         .toLowerCase()
    //         .replace(/\W+(.)/g, c => c.toUpperCase())
    //         .replace(/\W/g, '')

    //   }

    return <div className={classes.root}>
        {/* <CustomDialog 
            bulkDatas={bulkDatas}
            columns={columns}
            open={open}
            setOpen={setOpen}
        /> */}
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                {isLine === true ? 'Line Customers/Vendors ' : 'Customer/Vendor Details'} 
                </Typography>
            </Grid>
            <Grid
            style={{ display:"flex"}}>
            <div>
                <CSVReader
                        ref={buttonRef}
                        onFileLoad={handleOnFileLoad}
                        onError={handleOnError}
                        noProgressBar
                        // config={configOptions}
                        // onRemoveFile={handleOnRemoveFile}
                    >
                        {({file}) => (
                            <div>
                                <Button onClick={(e) => {
                                    if (buttonRef.current) {
                                        buttonRef.current.open(e)
                                    }
                                    }}
                                    variant="contained" color="primary">Bulk Import
                                </Button>
                            </div>
                        )}
                </CSVReader>
                <a className={classes.download} href={CustomerVendorDetailsCSV} color="primary" download>Download Template</a>
            </div>
                <Button className={classes.spacing} variant="contained" color="primary" onClick={onNewCustomerVendorDetail}>Add Customer/Vendor</Button>
            </Grid>

        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'id', field: 'id', hidden: true},
                {
                    title: 'Line',
                    field: 'isLineEnabled',
                    editable: 'never',
                    hidden: true,
                    render: rowData => {
                        return <span>{rowData.isLineEnabled ? 
                            <CheckIcon style={{ color: green[600] }} /> :
                            ""
                        }</span>
                    }
                },
                {
                    title: 'Type', 
                    field: 'type', 
                    hidden: !isDesktop, 
                    editable: 'never',
                    render: rowData =>{
                        return <span>{rowData.type === 1 ? 'Customer' : (rowData.type === 2 ?'Vendor': "Customer And Vendor")}</span>
                    }
                },
                {
                    title: 'Tax Profile', 
                    field: 'taxProfileID', 
                    hidden: !isDesktop, 
                    editable: 'never',
                    render: rowData =>{          
                            return  <span>{rowData.taxProfileID !== undefined && rowData.taxProfileID !== null && rowData.taxProfileID !== "" ? taxProfilesMap?.get(rowData.taxProfileID)?.taxProfileName : "" }</span>
                        
                    }
                },
                customerVendorDetailNameColumn,
                {title: 'Company Name', field: 'companyName', hidden: !isDesktop, editable: 'never'},
                {title: 'Phone Number', field: 'phoneNumber', hidden: !isDesktop, editable: 'never'},
                {title: 'Address', field: 'address', hidden: !isDesktop, editable: 'never'},    
                {title: 'GST Number', field: 'gstNumber', hidden: !isDesktop, editable: 'never'},
                {title: 'Opening Trays Balance', field: 'openingTraysBalance', hidden: !isDesktop || !isLine , editable: 'never'},
                {title: 'Opening Balance', field: 'openingBalance', hidden: !isDesktop, editable: 'never',
                render:(rowData) => {
                    return <span>{rowData.openingBalance.toLocaleString(user.locales,amountObj)}</span>
                }},
                {title: 'Email Address', field: 'emailAddress', hidden: !isDesktop, editable: 'never'}
            ]}
            data={customerVendorDetails.filter((el)=>{return el.isLineEnabled === isLine})}
            title={isLine === true ? 'Line Customers/Vendors Table' : 'Customer/Vendor Details Table'}
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true
            }}
            actions={actions}
        />
        <CustomerVendorDetail deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showCustomerVendorDetailDialog} handleClose={handleCustomerVendorDetailClose} onSave={onCustomerVendorDetailSave} onEdit={onCustomerVendorDetailUpdate}
               editMode={editMode} data={currentCustomerVendorDetail} isLine={isLine}
               taxProfiles={taxProfiles}
               />
        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onFarmDeleteConfirm}
                       title='Delete Details ?'
                       subText='This action will delete the customer/Vendor Details. Please confirm.'/>
    </div>
}

export default CustomerVendorDetailsView;
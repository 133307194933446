
import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import { getAllTaxes } from '../../common/functions';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//import Mobile from './components/mobile'
import moment from 'moment';
import Sale from "./components/sale";
import PaymentIcon from '@material-ui/icons/Payment';
import SaleDetails from './components/saleDetails'
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import SalePaymentDetails from "./components/salePaymentDetails";
import Icon from "@material-ui/core/Icon";
import InvoiceView from './components/invoice'
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import {useGlobalState} from '../../state';
import CheckIcon from '@material-ui/icons/Check';

import SaleMaterialsTable from './components/saleMaterialsTable';
import { version } from "../../common/enums";
import { updateOnboardingTaskStatus } from '../../common/functions';

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    red: {
        color: 'red'
    },
    green: {
        color: 'green'
    },
    gridMargin: {
        margin: theme.spacing(1.5)
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        minWidth: 160,
    },
    searchButton: {
        color: 'white',
        width: theme.spacing(21.25),
        backgroundColor: '#EF5350',
        '&:hover': {
            backgroundColor: '#E53935',
        },
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom: theme.spacing(4)
    },
    success: {
        color: 'white',
        backgroundColor: '#4CAF50',
        '&:hover': {
            backgroundColor: '#388E3C',
        },
        width: theme.spacing(13.75),
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom: theme.spacing(4)
    }
}));

let currentSale = undefined;
let currentInvoice = undefined;
let editMode = false;

const SalesView = ({ showError, showMessage, history }) => {

    const [currentSalePayment, setCurrentSalePayment] = useState({});
    const [sales, setSales] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [commissionAgents, setCommissionAgents] = useState([]);
    const [materialTypes, setMaterialTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSaleDialog, setShowSaleDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showSalePaymentDialog, setShowSalePaymentDialog] = useState(false);
    const [farms, setFarms] = useState([]);
    const [banks, setBanks] = useState([]);
    const [showSaleDetailsDialog, setShowSaleDetailsDialog] = useState(false)

    const [filterSelectedStartDate, setFilterSelectedStartDate] = useState(null);
    const [filterSelectedEndDate, setFilterSelectedEndDate] = useState(null);
    const [filterSelectedCustomerId, setFilterSelectedCustomerId] = useState('');
    const [filterSelectedLine, setFilterSelectedLine] = useState('');
    const [filterSelectedPaymentStatus, setFilterSelectedPaymentStatus] = useState('');
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [selectedLine, setSelectedLineId] = useState('');
    const [showGenerateInvoiceDialog, setShowGenerateInvoiceDialog] = useState(false)
    const [invoiceSetting, setInvoiceSetting] = useState({});
    const [errorMessage, setErrorMessage] = useState('')
    const [lineSales, setSaleLines] = useState([]);
    const [resetBillTotal, setResetBillTotal] = useState(false)
    const [trucks, setTrucks] = useState([]);

    const theme = useTheme();

    const csvHeader = ["Date", "Customer Name", "Material Type", "Item Name", "Inventory Type", "Quantity",
        "Sale Price", "Bill Value", "Payment Due(In Days)", "Bill Value", "Payment Status", "Balance",
        "Payment Date", "Payment Mode", "Payment Amount"]


    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const [{user}, dispatch] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }
    const classes = useStyles({ isDesktop });

    const handleFilterStartDateChange = date => {
        setFilterSelectedStartDate(date);
    };

    const handleFilterEndDateChange = date => {
        setFilterSelectedEndDate(date);
    };

    const handleFilterCustomerChange = (event) => {
        setSelectedCustomerId(event.target.value)
        setFilterSelectedCustomerId(customers[event.target.value].id)
    }

    const handleFilterLine = (event)=>{
        setSelectedLineId(event.target.value)
        setFilterSelectedLine(lineSales[event.target.value].id)
    }
    const handleFilterPaymentStatus = (event) => {
        setFilterSelectedPaymentStatus(event.target.value)
    }
    
    const removeErrorMessage = value =>{
        setErrorMessage('');
    }


    function onNewSale(event) {
        editMode = false;
        event.preventDefault();
        setShowSaleDialog(true);
    }

    function handleSaleClose() {
        setErrorMessage(null);
        currentSale = undefined;
        setShowSaleDialog(false);
        setResetBillTotal(false)
        if(errorMessage){
            removeErrorMessage();
        }
    }

    function handleSaleDetailsDialogClose() {
        setErrorMessage(null);
        currentSale = undefined
        setShowSaleDetailsDialog(false);
    }

    function openSalesDetailsDialog(event, rowData) {
        currentSale = rowData
        setShowSaleDetailsDialog(true)
    }

    function handleSalePaymentClose() {
        setErrorMessage(null);
        currentSale = undefined;
        setShowSalePaymentDialog(false);
    }

    function handleDeleteConfirmClose() {
        setErrorMessage(null);
        setShowDeleteConfirm(false);
    }

    function onGenerateInvoice(rowData) {
        currentInvoice = jsonCopy(rowData);
        setShowGenerateInvoiceDialog(true);
        setShowSaleDetailsDialog(false);
    }

    function jsonCopy(src) {
        return JSON.parse(JSON.stringify(src));
    }

    function handleGenerateInvoiceClose() {
        setErrorMessage(null);
        currentInvoice = undefined;
        setShowGenerateInvoiceDialog(false);
    }


    function loadTruckDetails() {
        return api.get(`trucks`)
        .then((res)=>{
            setTrucks(res);
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    const onSaleDeleteConfirm = () => {
        if (currentSale && currentSale.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`sale/${currentSale.id}`);

            response.then(res => {
                if (res) {
                    let index = sales.findIndex((srcSale) => {
                        return srcSale.id === currentSale.id;
                    });
                    let newSales = [...sales];
                    newSales.splice(index, 1);
                    setSales(newSales);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Sale not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentSale = undefined;
            });

        }
    };
    
        const onDelete = (sale) => {
            currentSale = sale;
            setShowDeleteConfirm(true);
            setShowSaleDetailsDialog(false);
        };

    const onSaleSave = (sale, setPopupLoading) => {
        //setLoading(true);
        // showError('');
        showMessage('');
        const response = api.post('sale', {
            ...sale,
        });

        response.then(res => {
            let totalAmount = 0
            if(res.salePayments !== undefined && res.salePayments !== null){
                res.salePayments.forEach((payment,index)=>{
                    if (payment.paymentMode !== -1) { 
                        totalAmount = totalAmount + payment.paymentAmount
                    }
                })
            }
            let tdsValue = res.totalBillValue * ((res.tds || 0) / 100)
            let tcsValue = res.totalBillValue * ((res.tcs || 0) / 100)

            if(totalAmount === 0){
                res = {
                    ...res,
                    paymentStatus : 0
               }
            }else if(totalAmount >= (res.totalBillValue + tcsValue) - (tdsValue)){
                res = {
                    ...res,
                    paymentStatus : 2
                }
            }else if(totalAmount < (res.totalBillValue + tcsValue) - (tdsValue)){
                res = {
                    ...res,
                    paymentStatus : 1
                }
            }
            // get all onboardingTasks and update if it is the first sale
            updateOnboardingTaskStatus(user, dispatch, "ADD_SALES");

            setSales([...sales, res]);
            setShowSaleDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Sale not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    };


    const onSaleEdit = (sale) => {
        editMode = true;
        if(user.versionID === version.pro){
            currentSale = sale ;
        }
        else{
            currentSale = {
                ...sale,
                onLineSaleSwitch: sale.onLineSale === 1 ? true : false,
                customerIndex: customers.findIndex((customer) => { return customer.id === sale.customerId; }),
                lineSaleIndex: lineSales.findIndex((lineSale) => { return lineSale.id === sale.lineSaleID; }),
                truckIndex: trucks.findIndex((el)=>{return el.id === sale.truckID}),
                commissionAgentIndex:commissionAgents.findIndex((commissionAgent)=> {return commissionAgent.id === sale.commissionAgentID })
            };
        }
        setShowSaleDialog(true);
        setShowSaleDetailsDialog(false);
    };

    const onPaymentEdit = (sale) => {
        setCurrentSalePayment({
            ...sale,
        });
        setShowSalePaymentDialog(true);
        setShowSaleDetailsDialog(false);
    };

    const onSaleUpdate = (sale, setPopupLoading) => {
        setLoading(true);
        // showError('');
        const response = api.post(`sale/${sale.id}`, {
            ...sale,

        });
        response.then((res) => {
            let index = sales.findIndex((srcSale) => {
                return srcSale.id === res.id;
            });

            let totalAmount = 0
            if(res.salePayments !== undefined && res.salePayments !== null){
                res.salePayments.forEach((payment,index)=>{
                    if (payment.paymentMode !== -1) {
                        totalAmount = totalAmount + payment.paymentAmount
                    }
                })
            }

            let tdsValue = res.totalBillValue * ((res.tds || 0) / 100)
            let tcsValue = res.totalBillValue * ((res.tcs || 0) / 100)


            if(totalAmount === 0){
                res = {
                    ...res,
                    paymentStatus : 0
               }
            } 
            else if(totalAmount >= (res.totalBillValue + tcsValue) - (tdsValue)){
                res = {
                    ...res,
                    paymentStatus : 2
                }
            }else if(totalAmount < (res.totalBillValue + tcsValue) - (tdsValue)){
                res = {
                    ...res,
                    paymentStatus : 1
                }
            }
            setSales([...sales.slice(0, index),
            { ...res },
            ...sales.slice(index + 1)]);
            setShowSaleDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('Sale not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    };

    /*const onSalePaymentUpdate = (sale) => {
        setShowSalePaymentDialog(false);
        setLoading(true);
        showError('');
        const response = api.post(`sale-payment/${sale.id}`, {
            ...sale,

        });
        response.then(() => {
            let index = sales.findIndex((srcSale) => {
                return srcSale.id === sale.id;
            });
            setSales([...sales.slice(0, index),
                {...sale},
                ...sales.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Sale not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };*/

    const onSalePaymentUpdate = (payment, sale, setPopupLoading) => {
        //setShowSalePaymentDialog(false);
        setLoading(true);
        showError('');
        const response = api.post(`sale-payment/${payment.id}`, {
            ...payment,

        });
        response.then(() => {
            let index = sales.findIndex((srcSale) => {
                return srcSale.id === sale.id;
            });

            let totalAmount = 0
            sale.salePayments.forEach((payment, index) => {
                totalAmount = totalAmount + payment.paymentAmount
            })
            let tdsValue = sale.totalBillValue * ((sale.tds || 0) / 100)
            let tcsValue = sale.totalBillValue * ((sale.tcs || 0) / 100)
            if (totalAmount === 0) {
                sale = {
                    ...sale,
                    paymentStatus: 0
                }
            } else if (totalAmount >= (sale.totalBillValue+ tcsValue) - (tdsValue)) {
                sale = {
                    ...sale,
                    paymentStatus: 2
                }
            } else if (totalAmount < (sale.totalBillValue+ tcsValue)  - (tdsValue)) {
                sale = {
                    ...sale,
                    paymentStatus: 1
                }
            }
            sale = {
                ...sale,
                totalAmountPaid: totalAmount
            }
            setSales([...sales.slice(0, index),
            { ...sale },
            ...sales.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Sale Payment not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    };

    const onSalePaymentSave = (payment, setPopupLoading) => {
        //setShowSalePaymentDialog(false);
       // setLoading(true);
               //0 - Paid
        //1 - Partially Paid
        //2 - Not Paid
        showError('');
        const response = api.post(`sale-payment`, {
            ...payment,
        });
        response.then((res) => {

            let tempCurrentSale = currentSalePayment
            if (tempCurrentSale.salePayments !== undefined && tempCurrentSale.salePayments !== null) {
                tempCurrentSale.salePayments = [
                    ...tempCurrentSale.salePayments,
                    res
                ]
            } else {
                tempCurrentSale.salePayments = [
                    res
                ]
            }
            setCurrentSalePayment({ ...tempCurrentSale })

            let totalAmount = 0
            tempCurrentSale.salePayments.forEach((payment, index) => {
                totalAmount = totalAmount + payment.paymentAmount
            })
            let tdsValue = tempCurrentSale.totalBillValue * ((tempCurrentSale.tds || 0) / 100)
            let tcsValue = tempCurrentSale.totalBillValue * ((tempCurrentSale.tcs || 0) / 100)
            if (totalAmount === 0) {
                tempCurrentSale = {
                    ...tempCurrentSale,
                    paymentStatus: 0
                }
            } else if (totalAmount >= ((tempCurrentSale.totalBillValue + tcsValue) - (tdsValue))) {
                tempCurrentSale = {
                    ...tempCurrentSale,
                    paymentStatus: 2
                }
            } else if (totalAmount < ((tempCurrentSale.totalBillValue + tcsValue) - (tdsValue))) {
                tempCurrentSale = {
                    ...tempCurrentSale,
                    paymentStatus: 1
                }
            }
            tempCurrentSale = {
                ...tempCurrentSale,
                totalAmountPaid: totalAmount
            }
            let index = sales.findIndex((srcSale) => {
                return srcSale.id === tempCurrentSale.id;
            });

            tempCurrentSale.totalAmountPaid -= tempCurrentSale.totalTaxAmountPaid

            setSales([...sales.slice(0, index),
            { ...tempCurrentSale },
            ...sales.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Sale not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    };

    const onSalePaymentDelete = (payment, sale) => {
        if (payment && payment.id) {
            //setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.post(`sale-payment-delete/${payment.id}`, {
                ...payment,
            });

            response.then(res => {
                if (res) {
                    let index = sales.findIndex((srcSale) => {
                        return srcSale.id === sale.id;
                    });

                    let totalAmount = 0
                    sale.salePayments.forEach((payment, index) => {
                        totalAmount = totalAmount + payment.paymentAmount
                    })
                    let tdsValue = sale.totalBillValue * (sale.tds / 100)
                    let tcsValue = sale.totalBillValue * (sale.tcs / 100)

                    if (totalAmount === 0) {
                        sale = {
                            ...sale,
                            paymentStatus: 0
                        }
                    } else if (totalAmount >= (sale.totalBillValue + tcsValue) - (tdsValue)) {
                        sale = {
                            ...sale,
                            paymentStatus: 2
                        }
                    } else if (totalAmount < (sale.totalBillValue + tcsValue) - (tdsValue)) {
                        sale = {
                            ...sale,
                            paymentStatus: 1
                        }
                    }
                    sale = {
                        ...sale,
                        totalAmountPaid: totalAmount
                    }

                    sale.totalAmountPaid -= sale.totalTaxAmountPaid

                    setSales([...sales.slice(0, index),
                    { ...sale },
                    ...sales.slice(index + 1)]);
                    setCurrentSalePayment(sale);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Sale not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentSale = undefined;
            });

        }
    };
    //Get all Sales API Call
    const getAllSales = () => {

        if (filterSelectedEndDate !== null && filterSelectedStartDate !== null &&
            filterSelectedEndDate < filterSelectedStartDate) {
            showError('Start Date is Greater than or Equal to End Date');
            return
            
        }
        let payload = {
            customerId: parseInt(filterSelectedCustomerId, 10),
            lineSaleID:parseInt(filterSelectedLine, 10),
            startDate: filterSelectedStartDate,
            endDate: filterSelectedEndDate,
            paymentStatus: parseInt(filterSelectedPaymentStatus, 10)
        }
        setShowSaleDialog(false);
        showError('');
        showMessage('');

        const response = api.post('sales', { ...payload });
        response.then(res => {
            setSales([...res]);
        }).catch(err => {
            setSales([]);
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Sales Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        
        return response;
       
    }

    //Get all customers API Call
    const getAllCustomers = () => {
        showError('');
        showMessage('');

        const response = api.get('customers-list');

        response.then(res => {
            setCustomers([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Customers Found');
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });

        return response;
    }



    //Get all materialTypes API Call
    const getAllMaterialTypes = () => {

        showError('');
        showMessage('');

        const response = api.get('material-types');

        response.then(res => {
            setMaterialTypes([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
        return response;
    }

    //Get all farms API Call
    const getAllFarms = () => {
        showError('');
        showMessage('');

        const response = api.get('farm');

        response.then(res => {
            setFarms([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
        return response;
    }

    //Get all farms API Call
    const getAllSaleLines = () => {
        showError('');
        showMessage('');

        const response = api.get('line_name');

        response.then(res => {
            setSaleLines([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
        return response;
    }

    //Get all Banks API Call
    const getAllBanks = () => {
        showError('');
        showMessage('');

        const response = api.get('bank');

        response.then(res => {
            setBanks([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Bank Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }

    const getAllInvoiceSettings = () => {
        showError('');
        showMessage('');

        const response = api.get('invoiceSettings');

        response.then(res => {
            setInvoiceSetting(res[0]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No InvoiceSettings Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }
    //Get all Commission Agent API Call
    const getAllCommissonAgent = () => {
        showError('');
        showMessage('');
        const response = api.get('/commission-agent-list');
        response.then(res => {
            setCommissionAgents([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });

        return response;
    };
    const generateExcel = function (data) {
        let csvData = [];

        csvData = [
            ...csvHeader,
            '\r\n'
        ];


        data.forEach((row) => {
            
            let paymentAmount = ""
            let paymentDate = ""
            let paymentMode = ""

            let paidAmount = 0;
            let balanceAmount = row.totalBillValue;
            let paymentStatus = row.paymentStatus === 1 ? "Partially Paid" : row.paymentStatus === 2 ? "Paid" : "Unpaid"

            if (row.salePayments !== null) {

                row.salePayments.forEach((value, index)=>{
                    paidAmount = paidAmount + value.paymentAmount

                    paymentAmount = paymentAmount + value.paymentAmount + ";"
                    paymentDate = paymentDate + moment(value.paymentDate).format("DD-MM-YYYY") + ";"
                    paymentMode = paymentMode + (value.paymentMode === 0 ? '' : (value.paymentMode === 1 ? 'Cash' : value.paymentBankName.String)) + ";"
                })


                balanceAmount = row.totalBillValue - paidAmount
            }

            let tempSaleMaterials = []
            for (let i = 0; i < row.saleMaterials.length; i++) {
                let material = row.saleMaterials[i]
                let index = tempSaleMaterials.findIndex((tempMaterial) => {
                    return tempMaterial.materialId === material.materialId && tempMaterial.costPerUnit === material.costPerUnit
                })
                
                if (index >= 0) {
                    // tempSaleMaterials[index].billValue + material.billValue)
                    tempSaleMaterials[index].billValue = tempSaleMaterials[index].billValue + material.billValue
                    tempSaleMaterials[index].quantity = tempSaleMaterials[index].quantity + material.quantity
                } else {
                    tempSaleMaterials = [
                        ...tempSaleMaterials,
                        material
                    ]
                }
            }

            tempSaleMaterials.forEach((value, index) => {
                let materialTypeName = "";
                materialTypes.forEach((material) => {
                    if (material.id === value.materialTypeId) {
                        materialTypeName = material.materialType
                    }
                })
                let inventoryType = value.inventoryType === 1 ? "FeedMill" : value.farmName.String + "(" + value.shedName.String + ")"

                let column = []

                column.push(moment(row.date).format("DD-MM-YYYY"), row.customerName, materialTypeName, value.materialName,
                    inventoryType, value.quantity, value.costPerUnit, value.billValue, row.paymentDueDate,
                    row.totalBillValue, paymentStatus, balanceAmount,
                    paymentDate, paymentMode, paymentAmount)

                csvData = csvData + column.toString() + '\r\n';
            });

        })


        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
        element.setAttribute('download', 'report.csv');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);


    }

    const clearDateRangeFilter = (sale) => {
        setFilterSelectedStartDate(null)
        setFilterSelectedLine(null)
        setFilterSelectedEndDate(null)
        setFilterSelectedCustomerId(null)
        setSelectedCustomerId(null)
        setSelectedLineId(null)
        setFilterSelectedPaymentStatus(null)
    }

    const onload = () => {
        setLoading(true);
        Promise.all([getAllCustomers(),
        getAllTaxes(api),
        getAllBanks(),
        getAllFarms(),
        getAllSales(),
        getAllInvoiceSettings(),
        getAllSaleLines(),
        loadTruckDetails(),
        getAllCommissonAgent(),
        getAllMaterialTypes()]).finally(() => {
            setLoading(false);
        });
    };

    useEffect(onload, []);



    /* <Mobile data={rowData}
             onPaymentEdit={() => {
                onPaymentEdit(rowData)
                }} />
             /*onEdit={() => {
                onSaleEdit(rowData)
                }}
                onDelete={() => {
                onDelete(rowData)*/
    let actions = [
        rowData => ({
            icon: () => <PaymentIcon color='error'>payment</PaymentIcon>,
            tooltip: 'Payment Details',
            onClick: (event, rowData) => onPaymentEdit(rowData),
            hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
        }),
        rowData => ({
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit sale Details',
            onClick: () => onSaleEdit(rowData),
            hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
        }),
        {
            icon: () => <DescriptionIcon color='primary'>Generate Invoice</DescriptionIcon>,
            tooltip: 'Generate Invoice',
            onClick: (event, rowData) => onGenerateInvoice(rowData)
        },
        rowData => ({
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete Sale Details',
            onClick: () => onDelete(rowData),
            hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
        }),
    ];
    let proActions = [
        rowData => ({
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit sale Details',
            onClick: () => onSaleEdit(rowData),
            hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
        }),
        rowData => ({
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete Sale Details',
            onClick: () => onDelete(rowData),
            hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
        }),
    ];
    /*if (isDesktop) {
        /*saleNameColumn = {title: 'Customer Name', field: 'customerName', hidden: !isDesktop, editable: 'never', defaultSort:'asc',
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'}};
         /*{
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit sale Details',
                onClick: (event, rowData) => onSaleEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete sale Details',
                onClick: (event, rowData) => onDelete(rowData)
            }
        actions = [
            {
                icon: () => <PaymentIcon color='error'>payment</PaymentIcon>,
                tooltip: 'Payment Details',
                onClick: (event, rowData) => onPaymentEdit(rowData)
            }
        ]
    }*/


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Sales
                </Typography>
            </Grid>
            <Grid  container
                  spacing={3}
                  justify="flex-end"
                  alignItems="center">
                <Grid>
                    <Button variant="contained" color="primary" onClick={onNewSale}>Add sale</Button>
                </Grid>
                <Grid item spacing={1}>
                    <Button variant="outlined" color="primary"
                        disabled={sales.length < 1}
                        onClick={() => generateExcel(sales)}
                        endIcon={<GetAppIcon />}>Download Excel</Button>
                </Grid>
            </Grid>
        </Grid>
        <Grid container
            spacing={3}
            justify="flex-start"
            alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    autoOk
                    className={classes.formControl}
                    margin="normal"
                    variant="inline"
                    label="From"
                    format="dd/MM/yyyy"
                    value={filterSelectedStartDate || null}
                    onChange={handleFilterStartDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardDatePicker
                    className={classes.formControl}
                    autoOk
                    margin="normal"
                    variant="inline"
                    label="To"
                    format="dd/MM/yyyy"
                    value={filterSelectedEndDate || null}
                    onChange={handleFilterEndDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
            {
                (user.versionID === 300 ) && 
                <FormControl className={classes.formControl}>
                <InputLabel id="customerName-select-outlined-label">
                    Customer Name
                    </InputLabel>
                <Select
                    id="customerName-select"
                    value={selectedCustomerId !== undefined ? selectedCustomerId : ''}
                    onChange={handleFilterCustomerChange}
                    name="customerId"
                >
                    {customers !== undefined && customers !== null ?
                        customers.map((customer, index) =>
                            <MenuItem key={customer.id} value={index}>{customer.name}</MenuItem>
                        ) : ""}
                </Select>
               </FormControl>
            }
            {true ?
                "" :
            <FormControl className={classes.formControl}>
                <InputLabel id="filterSelectedPaymentStatus-select-outlined-label">
                    Payment Status
                    </InputLabel>
                <Select
                    id="filterSelectedPaymentStatus"
                    value={filterSelectedPaymentStatus !== undefined ? filterSelectedPaymentStatus : ''}
                    onChange={handleFilterPaymentStatus}
                    name="filterSelectedPaymentStatus"
                >
                    <MenuItem key={0} value={3}>Paid</MenuItem>
                    <MenuItem key={1} value={2}>Partially Paid</MenuItem>
                    <MenuItem key={2} value={1}>Not Paid</MenuItem>
                </Select>
            </FormControl>
            }
            {
                (user.versionID === 300 ) &&
                <FormControl className={classes.formControl}>
                <InputLabel id="lineSale-select-outlined-label">
                    Line Name
                </InputLabel>
                <Select
                    id="filterSelectedLine"
                    value={selectedLine !== undefined ? selectedLine : ''}
                    onChange={handleFilterLine}
                    name="lineSaleID"
                >
                    {lineSales !== undefined && lineSales !== null ?
                        lineSales.map((lineSale, index) =>
                            <MenuItem key={lineSale.id} value={index}>{lineSale.name}</MenuItem>
                        ) : ""}
                </Select>
            </FormControl>
            }
            <Grid>
                <Button onClick={getAllSales} className={classes.success}>Search <SearchIcon /></Button>
                <Button onClick={clearDateRangeFilter} className={classes.searchButton}>Clear Search <CloseIcon /></Button>
            </Grid>
        </Grid>
        {
            (user.versionID === version.pro) 
            ? 
            <SaleMaterialsTable saleMaterials={sales} actions={proActions} classes={classes}/>
            : 
           <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'id', field: 'id', hidden: true },
                { title: 'No', field: 'displayId', defaultSort: 'desc' },
                {
                    title: 'Date', field: 'date', type: 'datetime',
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    editable: 'never',
                    render: rowData => {
                        return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
                    }
                },
                {
                    title:"Customer Name", field: 'customerName',
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    editable: 'never',
                    render: rowData => {
                        return <span>{rowData.customerName !== '' ? rowData.customerName : 'Other'}</span>
                    }
                },
                {
                    title: 'Line Sales', field: 'onLineSales', hidden: true,
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    editable: 'never',
                    render: rowData => {
                        return <span>{rowData.onLineSale ? <CheckIcon style={{color:'green'}} /> : ""}</span>
                    }
                },
                {
                    title: 'Payment Due (in Days)', field: 'paymentDueDate', editable: 'never', hidden: true,
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    render: rowData => {
                        var entryDate = moment(rowData.date).format("DD-MM-YYYY")
                        var dueDate = moment(entryDate, "DD-MM-YYYY").add('days', rowData.paymentDueDate);//entry date + due days
                        var currentDate = moment(new Date());
                        var one_day = 1000 * 60 * 60 * 24 //one day in MillSec
                        var remainingDueDays = Math.trunc(Math.abs((currentDate.diff(dueDate)) / one_day))
                        if (rowData.paymentDueDate === 0) {
                            return <span> - </span>
                        }
                        if (moment(currentDate).isAfter(dueDate)) {
                            return <span>0</span>
                        }

                        return <span>{remainingDueDays + 1}</span>
                    }
                },
                
                {
                    title: 'Bill Value', field: 'totalBillValue', editable: 'never',
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    render: rowData => {
                        return <span>{(rowData.totalBillValue).toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'TDS', field: 'tds', editable: 'never',hidden: true,
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: rowData => {
                        return <span>{(parseFloat((rowData.totalBillValue * (rowData.tds / 100)).toFixed(1))).toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'TCS', field: 'tcs', editable: 'never',hidden: true,
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: rowData => {
                        let gstAmount = 0;
                        let saleMaterials = rowData.saleMaterials;
                        saleMaterials.forEach((saleMaterial)=>{
                            let gstTaxAmt =  saleMaterial.gst ? parseFloat((saleMaterial.billValue * (saleMaterial.gst / 100)).toFixed(2)) : 0;
                            gstAmount += gstTaxAmt;
                        });
                        let billValueTCS = rowData.totalBillValue * (rowData.tcs / 100);
                        let gstValueTCS = gstAmount * (rowData.tcs / 100);
                        let totalTCS = Math.ceil(billValueTCS + gstValueTCS);
                        return <span>{(totalTCS).toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'GST', field: 'gst', editable: 'never',hidden: true,
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: rowData => {
                        let gstAmount = 0;
                        if (rowData && rowData.saleMaterials) {
                            let saleMaterials = rowData.saleMaterials
                            saleMaterials.forEach(saleMaterial => {
                                let gstTaxAmt = saleMaterial.gst ? parseFloat((saleMaterial.billValue * (saleMaterial.gst / 100)).toFixed(2)) : 0;
                                gstAmount += gstTaxAmt
                            })
                        }
                        return <span>{gstAmount.toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'Total', field: 'total', editable: 'never',
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: rowData => {
                        let totalAmount = 0
                        let gstAmount = 0
                        let tdsAmount = 0
                        let tcsAmount = 0
                        if (rowData && rowData.saleMaterials) {
                            let saleMaterials = rowData.saleMaterials
                            saleMaterials.forEach(saleMaterial => {
                                let gstTaxAmt = parseFloat((saleMaterial.billValue * ((saleMaterial.gst ? saleMaterial.gst : 0) / 100)).toFixed(2))
                                let tdsTaxAmt = parseFloat((saleMaterial.billValue * (rowData.tds ? rowData.tds : 0) / 100).toFixed(2))
                                let tcsTaxAmt = parseFloat((saleMaterial.billValue * (rowData.tcs ? rowData.tcs : 0) / 100).toFixed(2))
                                gstAmount += gstTaxAmt
                                tdsAmount += tdsTaxAmt
                                tcsAmount += tcsTaxAmt
                            })
                        }
                        let gstTCSVal = gstAmount * (rowData.tcs/100)
                        let totalTCS = Math.ceil(tcsAmount + gstTCSVal)
                        totalAmount = rowData.totalBillValue + totalTCS + gstAmount - tdsAmount
                        return <span>{totalAmount.toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'Remaining Amount', field: 'remainingAmount', editable: 'never',
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    render: rowData => {
                        let totalAmount = 0
                        let gstAmount = 0
                        let tdsAmount = 0
                        let tcsAmount = 0
                        let billValue = 0
                        let totalAmountPaid = 0
                        if (rowData && rowData.saleMaterials) {
                            let saleMaterials = rowData.saleMaterials
                            saleMaterials.forEach(saleMaterial => {
                                let gstTaxAmt = parseFloat((saleMaterial.billValue * (saleMaterial.gst ? saleMaterial.gst : 0) / 100).toFixed(2))
                                let tdsTaxAmt = parseFloat((saleMaterial.billValue * (rowData.tds ? rowData.tds : 0) / 100).toFixed(2))
                                let tcsTaxAmt = parseFloat((saleMaterial.billValue * (rowData.tcs ? rowData.tcs : 0) / 100).toFixed(2))
                                gstAmount += gstTaxAmt
                                tdsAmount += tdsTaxAmt
                                tcsAmount += tcsTaxAmt
                                billValue += saleMaterial.billValue
                            })
                        }
                        if (rowData && rowData.salePayments && rowData.salePayments.length) {
                            let salePayments = rowData.salePayments
                            salePayments.forEach(ele => {
                                if (ele.paymentMode  !== -1) { // Exclude tax payment
                                    totalAmountPaid += ele.paymentAmount
                                }
                            })
                        }
                        let gstTCSVal = gstAmount * (rowData.tcs/100)
                        let totalTCS = Math.ceil(tcsAmount + gstTCSVal)
                        totalAmount = billValue + totalTCS + gstAmount - tdsAmount
                        let reminingAmount = totalAmount - totalAmountPaid;

                        if (rowData.paymentStatus === 2 && reminingAmount < 1) {
                            reminingAmount = 0
                        }

                        return <span>{reminingAmount.toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'Payment Status', field: 'paymentStatus', editable: 'never',
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    render: (rowData) => {

                        let statusIcon = [
                        <Tooltip title="Not Paid"><CloseIcon className={classes.red} /></Tooltip>,
                        <Tooltip title="Tax Not Paid"><CancelIcon style={{fontSize:"12"}}  className={classes.red} /></Tooltip>
                        ]
                        
                        let billValue = 0
                        rowData.saleMaterials.forEach(material => {
                            billValue += ((material.quantity - (material.freeQuantity ? material.freeQuantity : 0)) * (material.costPerUnit - material?.discountPerUnit))
                        })

                        // let tdsValue = rowData.totalBillValue * (rowData.tds / 100)
                        let remaningBalance = (billValue + ((billValue * (rowData.tcs / 100))) - (billValue * (rowData.tds/100)) - rowData.totalAmountPaid)

                        let remaningBalanceWithoutTaxes =((billValue * (rowData.tcs / 100)) + (billValue * (rowData.tds/100))) - Math.ceil(rowData.totalTaxAmountPaid)

                        if (rowData.paymentStatus === 1 && remaningBalance >= 1) {
                            statusIcon.shift()
                            statusIcon.unshift(<Tooltip title="Partially Paid"><QueryBuilderIcon /></Tooltip>)
                        } else if (rowData.paymentStatus === 2 || (rowData.paymentStatus === 1 && remaningBalance < 1)) {
                            statusIcon.shift()
                            statusIcon.unshift(<Tooltip title="Paid"><DoneIcon className={classes.green} /></Tooltip>)
                        }

                        if (rowData.totalTaxAmountPaid > 0 && remaningBalanceWithoutTaxes > 0) { // Partially paid
                            statusIcon.pop()
                            statusIcon.push(<Tooltip title="Tax Partially Paid"><QueryBuilderIcon style={{fontSize:"12"}}  /></Tooltip>)
                        } else if (rowData.totalTaxAmountPaid > 0 && remaningBalanceWithoutTaxes <= 0) { // Fully paid
                            statusIcon.pop()
                            statusIcon.push(<Tooltip title="Tax Paid"><CheckCircleIcon style={{fontSize:"12"}}  className={classes.green} /></Tooltip>)
                        }
                        if (rowData.tcs === 0 && rowData.tds === 0) {
                            statusIcon.pop()
                        }

                        return <div style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center"
                        }} >{statusIcon}</div>
                    }
                }

            ]}
            data={sales}
            title='Sales Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,

                toolbar: true,
            }}
            onRowClick={openSalesDetailsDialog}
            actions={actions}
        />
        }
        <Sale deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showSaleDialog} handleClose={handleSaleClose} onSave={onSaleSave} onEdit={onSaleUpdate}
            editMode={editMode} data={currentSale} customers={customers} materialTypes={materialTypes} resetBillTotal={resetBillTotal} setResetBillTotal={setResetBillTotal}
            farms={farms} showError={showError} lineSales={lineSales} trucks={trucks}  commissionAgents={commissionAgents}/>

        <InvoiceView showDialog={showGenerateInvoiceDialog} handleClose={handleGenerateInvoiceClose}
            data={currentInvoice}
            invoiceSetting={invoiceSetting} />

        <SalePaymentDetails showPaymentDetailsDialog={showSalePaymentDialog} handlePaymentDetailsClose={handleSalePaymentClose}
            onPaymentEdit={onSalePaymentUpdate} data={currentSalePayment} banks={banks} onSalePaymentSave={onSalePaymentSave}
            onSalePaymentDelete={onSalePaymentDelete} loading={loading} />

        <ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onSaleDeleteConfirm}
            title='Delete Sale ?'
            subText='This action will delete the sale Details. Please confirm.' />

        <SaleDetails trucks={trucks} lineSales={lineSales} handleSaleDetailsDialogClose={handleSaleDetailsDialogClose} showSaleDetailsDialog={showSaleDetailsDialog}
            data={currentSale} onPaymentEdit={onPaymentEdit} onSaleEdit={onSaleEdit} onGenerateInvoice={onGenerateInvoice} onDelete={onDelete} />
    </div>
}

export default SalesView;
import React, { useCallback, useEffect, useState } from "react";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from "@material-ui/styles/makeStyles";
import palette from "../../../theme/palettes/default"
import api from '../../../common/api';

const useStyles = makeStyles(theme => ({
   table: {
      borderCollapse: 'collapse',
      color: theme.palette.text.primary,
      width: '100%',
      maxWidth: '500px',
      border: '1px solid #999999',
      borderRadius: '10px',
      '& th, td': {
         padding: theme.spacing(1),
         textAlign: 'left'
      },
      '& th': {
         backgroundColor: '#f2f2f2'
      },
      '& tbody': {
         backgroundColor: theme.palette.common.white
      }
   },
   featureName: {
      fontWeight: 'bold'
   },
   tableIcon: {
      marginLeft: theme.spacing(2),
      fontWeight: 'bold',
   },
   checkIcon: {
      color: palette.buttonBackground.primary,
   },
   crossIcon: {
      color: palette.buttonBackground.secondary,
      opacity: 0.8
   },
}))

function FeatureTable() {
   const [features, setFeatures] = useState([])

   const classes = useStyles();
   const getAllFeatures = useCallback(() => {
      const response = api.get('/getAllVersionFeatures')
      response.then(res => {
         setFeatures(res)
      })
   }, [])

   useEffect(() => {
      getAllFeatures()
   }, [getAllFeatures])

   return (
      <table className={classes.table}>
         <thead>
            <tr>
                  <th></th>
                  <th>Pro+</th>
                  <th>Pro</th>
                  <th>Lite</th>
            </tr>
         </thead>
         <tbody>
            {features.map((feature, index) => (
               <tr key={index}>
                  <td className={classes.featureName} >{feature.name}</td>
                  <td className={classes.tableIcon}>{feature.proPlus ? <CheckIcon className={classes.checkIcon} /> : <CloseIcon className={classes.crossIcon} />}</td>
                  <td className={classes.tableIcon}>{feature.pro ? <CheckIcon className={classes.checkIcon} /> : <CloseIcon className={classes.crossIcon} />}</td>
                  <td className={classes.tableIcon}>{feature.lite ? <CheckIcon className={classes.checkIcon} /> : <CloseIcon className={classes.crossIcon} />}</td>
               </tr>
            ))}
         </tbody>
      </table>
   );
}

export default FeatureTable;

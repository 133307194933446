import React, {useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog,MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import moment from 'moment';
import Breed from "./components/breed";
import Icon from '@material-ui/core/Icon';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import PetsIcon from '@material-ui/icons/Pets';
import HomeIcon from '@material-ui/icons/Home';
import Link from '@material-ui/core/Link';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useGlobalState } from '../../state';
import { updateOnboardingTaskStatus } from '../../common/functions';

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    breadcrumbs: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));

let currentBreed = undefined;
let editMode = false;

const BreedsView = ({showError, showMessage, history}) => {
    const [{user}, dispatch] = useGlobalState();

    const [breeds, setBreeds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showBreedDialog, setShowBreedDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    function onNewBreed(event) {
        editMode = false;
        event.preventDefault();
        setShowBreedDialog(true);
    }

    function handleBreedClose() {
        currentBreed = undefined;
        setErrorMessage(null);
        setShowBreedDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onFarmDeleteConfirm = () => {
        if (currentBreed && currentBreed.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`breed/${currentBreed.id}`);

            response.then(res => {
                if (res) {
                    let index = breeds.findIndex((srcBreed) => {
                        return srcBreed.id === currentBreed.id;
                    });
                    let newBreeds = [...breeds];
                    newBreeds.splice(index, 1);
                    setBreeds(newBreeds);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Breed not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentBreed = undefined;
            });

        }
    };

    const onDelete = (breed) => {
        currentBreed = breed;
        setShowDeleteConfirm(true);
    };

    const onBreedSave = (breed, setpopuploading) => {
        //setLoading(true);
        const response = api.post('breed', {
            ...breed
        });

        response.then(res => {
            // get all onboardingTasks and update if it is the first breed
            updateOnboardingTaskStatus(user, dispatch, "ADD_BREEDS");
            
            setBreeds([...breeds, res]);
            setShowBreedDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Breed not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };


    const onBreedEdit = (breed) => {
        currentBreed = breed;
        editMode = true;
        setShowBreedDialog(true);
    };

    const onBreedUpdate = (breed, setpopuploading) => {
        setShowBreedDialog(false);
        //setLoading(true);
        // showError('');
        const response = api.post(`breed/${breed.id}`, {
            ...breed
        });
        response.then(() => {
            let index = breeds.findIndex((srcBreed) => {
                return srcBreed.id === breed.id;
            });
            setBreeds([...breeds.slice(0, index),
                {...breed},
                ...breeds.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Breed not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };

    //Get all Breeds API Call
    const getAllBreeds = () => {
        setShowBreedDialog(false);
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('breed');

        response.then(res => {
            setBreeds([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Breeds Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(getAllBreeds, []);

    function openVacSchedule(event, rowData) {
        event.preventDefault();
        event.stopPropagation();
        history.push(`/breed/${rowData.id}/vaccination-schedule`);
    }

    function openStdProduction(event,rowData){
        event.preventDefault();
        event.stopPropagation();
        history.push(`/breed/${rowData.id}/std-prod`);
    }

    let breedNameColumn = {
        title: 'Breeds', field: 'name',
        render: rowData => <Mobile breed={rowData}
                                   onEdit={() => {
                                       onBreedEdit(rowData)
                                   }}
                                   onDelete={() => {
                                       onDelete(rowData)
                                   }}
                                   openVacSchedule={(event) => {
                                       openVacSchedule(event, rowData);
                                   }}

                                   openStdProduction={(event) => {
                                       openStdProduction(event, rowData);
                                   }}
        />
    };
    let actions = [];
    if (isDesktop) {
        breedNameColumn = {title: 'Name', field: 'name'};
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Breed',
                onClick: (event, rowData) => onBreedEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Breed',
                onClick: (event, rowData) => onDelete(rowData)
            }
        ]
    }


    return <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
            <Link color="inherit" href="#" onClick={() => history.push('/')} className={classes.link}>
                <HomeIcon className={classes.icon}/>
                Home
            </Link>
            <Typography color="textPrimary" className={classes.link}>
                <PetsIcon className={classes.icon}/>
                Breeds
            </Typography>
        </Breadcrumbs>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Breeds Overview
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewBreed}>Add Breed</Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'id', field: 'id', hidden: true},
                breedNameColumn,
                {title: 'Modified By', field: 'modifiedBy', hidden: !isDesktop, editable: 'never'},
                {
                    title: 'Modified',
                    field: 'modifiedTime',
                    type: 'datetime',
                    editable: 'never',
                    hidden: !isDesktop,
                    render: rowData => {
                        return <span>{moment(rowData.modifiedTime).format("DD-MM-YYYY HH:mm")}</span>
                    }
                },
                {
                    title: 'More',
                    field: 'id',
                    editable: 'never',
                    hidden: !isDesktop,
                    render: rowData => {
                        return <ButtonGroup  size="small" color="primary" aria-label="outlined primary button group">
                            <Button onClick={(e) => {
                                openVacSchedule(e, rowData)
                            }} size="small" color="primary">Vaccination Schedule</Button>
                            <Button onClick={(e) => {
                                openStdProduction(e, rowData)
                            }} size="small" color="secondary">Breed Standard</Button>
                        </ButtonGroup>
                    }
                }
            ]}
            title='Breeds Table'
            data={breeds}
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true
            }}
            actions={actions}
            onRowClick={isDesktop ? openVacSchedule : undefined}
        />
        <Breed deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showBreedDialog} handleClose={handleBreedClose} onSave={onBreedSave} onEdit={onBreedUpdate}
               editMode={editMode} breed={currentBreed}/>
        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onFarmDeleteConfirm}
                       title='Delete Breed ?'
                       subText='This action will delete the breed and all vaccination schedules associated with it.Please confirm.'/>
    </div>
}

export default BreedsView;